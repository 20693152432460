export class Model {
  public pageLoading: boolean = true;
  public planId: number = 0;
  public saveButtonActive: boolean = true;
  public saveButtonLoading: boolean = false;
  public saveButtonCompleted: boolean = false;
  public homeImagePC: InputImage = new InputImage();
  public homeImageSP: InputImage = new InputImage();
  public catchCopyTitle: InputText = new InputText(100);
  public catchCopyBody: InputText = new InputText(200);
  public signUpButton: InputText = new InputText(50);
  public catchPositionPC: InputText = new InputText(100);
  public catchPositionSP: InputText = new InputText(100);
  public homeImageTextColorPC: InputText = new InputText(100);
  public homeImageTextColorSP: InputText = new InputText(100);
  public promotion: InputPromotions = new InputPromotions();
  public point: InputPoints = new InputPoints();

  public name: InputText = new InputText(50);
  public explanation: InputText = new InputText(1000);
  public helps: Helps = new Helps();
}
export class Helps {
  public homeImagePC: Help = new Help();
  public homeImageSP: Help = new Help();
  public catchCopyTitle: Help = new Help();
  public catchCopyBody: Help = new Help();
  public signUpButton: Help = new Help();
  public catchPositionPC: Help = new Help();
  public catchPositionSP: Help = new Help();
  public promotion: Help = new Help();
  public promotionTitle: Help = new Help();
  public promotionBody: Help = new Help();
  public promotionJumpTo: Help = new Help();
  public point: Help = new Help();
  public pointTitle: Help = new Help();
  public pointBody: Help = new Help();
  public pointIcon: Help = new Help();
  public pointColor: Help = new Help();
  public homeImageTextColorPC: Help = new Help();
  public homeImageTextColorSP: Help = new Help();
}
export class Help {
  public hidden: boolean = true;
  public switch(): void {
    this.hidden = !this.hidden;
  }
}
export class InputText {
  public constructor(maxLength: number, minLength = 0) {
    this.maxLength = maxLength;
    this.minLength = minLength;
  }
  public value: string = "";
  public maxLength: number = 0;
  public minLength: number = 0;
  public errors: Array<Error> = new Array<Error>();
  get isError(): boolean {
    return this.errors.length > 0;
  }
}
export class Error {
  public constructor(message: string, index: number | null = null) {
    this.message = message;
    this.index = index;
  }
  public message: string;
  public index: number | null;
}
export class InputImage {
  public get isSetSrc(): boolean {
    return this.base64Src != null || this.defaultFileImageUrl != null;
  }
  public loacalFilePath: string | null = null;
  public defaultFileImageUrl: string | null = null;
  public base64Src: string | null = null;
  public errors: Array<Error> = new Array<Error>();
  get isError(): boolean {
    return this.errors.length > 0;
  }
  get loacalFileExtension(): string | null {
    if (this.loacalFilePath == null) {
      return null;
    }
    return this.loacalFilePath.slice(
      ((this.loacalFilePath.lastIndexOf(".") - 1) >>> 0) + 2
    );
  }
}
export class InputPromotions {
  public items: Array<InputPromotion> = new Array<InputPromotion>();
  public selectedItem: InputPromotion | null = null;
  get isSelected(): boolean {
    return this.selectedItem != null;
  }
  public errors: Array<Error> = new Array<Error>();
  get isError(): boolean {
    return this.errors.length > 0;
  }
  get isFull(): boolean {
    return this.items.length >= 10;
  }
}
export class InputPromotion {
  public constructor(promotion: InputPromotions) {
    this.owner = promotion;
    let key = 0;
    for (let i = 0; i <= this.owner.items.length - 1; i++) {
      if (this.owner.items[i].key >= key) {
        key = this.owner.items[i].key + 1;
      }
    }
    this.key = key;
  }
  public owner: InputPromotions | null = null;
  public key: number = 0;
  public id: number | null = null;
  public title: InputText = new InputText(100);
  public body: InputText = new InputText(500);
  public type: InputPromotionType = InputPromotionType.none;
  public image: InputImage = new InputImage();
  public youtube: InputText = new InputText(50);
  public errors: Array<Error> = new Array<Error>();
  get isError(): boolean {
    return this.errors.length > 0;
  }
  get isSelected(): boolean {
    if (this.owner != null) {
      return this == this.owner?.selectedItem;
    } else {
      return false;
    }
  }
  get isUp(): boolean {
    if (this.owner != null) {
      return this.owner?.items.indexOf(this) != 0;
    } else {
      return false;
    }
  }
  get isDown(): boolean {
    if (this.owner != null) {
      return this.owner.items.indexOf(this) != this.owner.items.length - 1;
    } else {
      return false;
    }
  }
  public up(): void {
    if (this.owner != null) {
      const from = this.owner.items.indexOf(this);

      this.owner.items.splice(
        from - 1,
        2,
        this.owner.items[from + 0],
        this.owner.items[from - 1]
      );
    }
  }
  public down(): void {
    if (this.owner != null) {
      const from = this.owner.items.indexOf(this);

      this.owner.items.splice(
        from,
        2,
        this.owner.items[from + 1],
        this.owner.items[from + 0]
      );
    }
  }
}
export enum InputPromotionType {
  image = "image",
  youtube = "youtube",
  none = "none",
}
export class InputPoints {
  public items: Array<InputPoint> = new Array<InputPoint>();
  public selectedItem: InputPoint | null = null;
  get isSelected(): boolean {
    return this.selectedItem != null;
  }
  public errors: Array<Error> = new Array<Error>();
  get isError(): boolean {
    return this.errors.length > 0;
  }
  get isFull(): boolean {
    return this.items.length >= 3;
  }
}
export class InputPoint {
  public constructor(promotion: InputPoints) {
    this.owner = promotion;
    let key = 0;
    for (let i = 0; i <= this.owner.items.length - 1; i++) {
      if (this.owner.items[i].key >= key) {
        key = this.owner.items[i].key + 1;
      }
    }
    this.key = key;
  }
  public owner: InputPoints | null = null;
  public key: number = 0;
  public id: number | null = null;
  public title: InputText = new InputText(100);
  public body: InputText = new InputText(500);
  public icon: InputText = new InputText(100);
  public color: InputText = new InputText(100);
  public errors: Array<Error> = new Array<Error>();
  get isError(): boolean {
    return this.errors.length > 0;
  }
  get isSelected(): boolean {
    if (this.owner != null) {
      return this == this.owner?.selectedItem;
    } else {
      return false;
    }
  }
  get isUp(): boolean {
    if (this.owner != null) {
      return this.owner?.items.indexOf(this) != 0;
    } else {
      return false;
    }
  }
  get isDown(): boolean {
    if (this.owner != null) {
      return this.owner.items.indexOf(this) != this.owner.items.length - 1;
    } else {
      return false;
    }
  }
  public up(): void {
    if (this.owner != null) {
      const from = this.owner.items.indexOf(this);

      this.owner.items.splice(
        from - 1,
        2,
        this.owner.items[from + 0],
        this.owner.items[from - 1]
      );
    }
  }
  public down(): void {
    if (this.owner != null) {
      const from = this.owner.items.indexOf(this);

      this.owner.items.splice(
        from,
        2,
        this.owner.items[from + 1],
        this.owner.items[from + 0]
      );
    }
  }
}
