var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page", class: { loading: _vm.model.pageLoading } },
    [
      _c("div", { staticClass: "breadcrumbs" }, [
        _c("ul", [
          _c(
            "li",
            [
              _c(
                "router-link",
                { attrs: { to: { name: "CreatorDashboard" } } },
                [_vm._v("ダッシュボード")]
              ),
            ],
            1
          ),
          _c(
            "li",
            [
              _c("fa-icon", { attrs: { icon: "angle-right" } }),
              _c("router-link", { attrs: { to: { name: "CreatorChannel" } } }, [
                _vm._v("サイト"),
              ]),
            ],
            1
          ),
          _c(
            "li",
            [
              _c("fa-icon", { attrs: { icon: "angle-right" } }),
              _c("span", [_vm._v("ホーム画面の編集")]),
            ],
            1
          ),
        ]),
      ]),
      _c("hr"),
      _c("div", { staticClass: "formGroupV2" }, [
        _c("div", { staticClass: "header" }, [
          _c("div", { staticClass: "row1" }, [
            _c("span", { staticClass: "title" }, [
              _vm._v("ホームイメージ画像（PC）"),
            ]),
            _c(
              "span",
              {
                staticClass: "help-button",
                on: {
                  click: function ($event) {
                    return _vm.model.helps.homeImagePC.switch()
                  },
                },
              },
              [_c("fa-icon", { attrs: { icon: ["far", "question-circle"] } })],
              1
            ),
          ]),
          _c("div", { staticClass: "row2" }, [
            _c(
              "div",
              {
                staticClass: "help-body",
                class: { hidden: _vm.model.helps.homeImagePC.hidden },
              },
              [_vm._m(0)]
            ),
          ]),
        ]),
        _c("div", { staticClass: "body" }, [
          _c("div", { staticClass: "fileupload" }, [
            _c("div", { staticClass: "left" }, [
              _c("div", { staticClass: "image" }, [
                _vm.model.homeImagePC.base64Src != null
                  ? _c("img", {
                      attrs: { src: _vm.model.homeImagePC.base64Src },
                    })
                  : _vm._e(),
                _vm.model.homeImagePC.defaultFileImageUrl != null
                  ? _c("img", {
                      attrs: { src: _vm.model.homeImagePC.defaultFileImageUrl },
                    })
                  : _vm._e(),
                !_vm.model.homeImagePC.isSetSrc
                  ? _c("span", [_vm._v("ファイルを選択してください")])
                  : _vm._e(),
              ]),
            ]),
            _c("div", { staticClass: "right" }, [
              _vm.model.homeImagePC.loacalFilePath != null
                ? _c(
                    "span",
                    { staticClass: "path" },
                    [
                      _vm._v(_vm._s(_vm.model.homeImagePC.loacalFilePath)),
                      _c("fa-icon", {
                        attrs: { icon: "times" },
                        on: {
                          click: function ($event) {
                            return _vm.deleteImage(
                              _vm.model.homeImagePC,
                              "model.homeImagePC"
                            )
                          },
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.elementClick("model.homeImagePC")
                    },
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "icon" },
                    [_c("fa-icon", { attrs: { icon: "folder-open" } })],
                    1
                  ),
                  _c("span", [_vm._v("ファイルを選択")]),
                ]
              ),
              _c("input", {
                attrs: {
                  type: "file",
                  id: "model.homeImagePC",
                  accept: "image/*",
                },
                on: {
                  input: function ($event) {
                    return _vm.changeImage(
                      $event,
                      _vm.model.homeImagePC,
                      "homeImagePC"
                    )
                  },
                },
              }),
            ]),
          ]),
          _c(
            "div",
            {
              staticClass: "errors",
              class: { hidden: !_vm.model.homeImagePC.isError },
            },
            _vm._l(_vm.model.homeImagePC.errors, function (item1) {
              return _c(
                "div",
                {
                  key: _vm.model.homeImagePC.errors.indexOf(item1),
                  staticClass: "error",
                },
                [
                  _c(
                    "div",
                    { staticClass: "icon" },
                    [_c("fa-icon", { attrs: { icon: "exclamation-circle" } })],
                    1
                  ),
                  _c("span", [_vm._v(_vm._s(item1.message))]),
                ]
              )
            }),
            0
          ),
        ]),
      ]),
      _c("hr"),
      _c("div", { staticClass: "formGroupV2" }, [
        _c("div", { staticClass: "header" }, [
          _c("div", { staticClass: "row1" }, [
            _c("span", { staticClass: "title" }, [
              _vm._v("ホームイメージ画像（SP）"),
            ]),
            _c(
              "span",
              {
                staticClass: "help-button",
                on: {
                  click: function ($event) {
                    return _vm.model.helps.homeImageSP.switch()
                  },
                },
              },
              [_c("fa-icon", { attrs: { icon: ["far", "question-circle"] } })],
              1
            ),
          ]),
          _c("div", { staticClass: "row2" }, [
            _c(
              "div",
              {
                staticClass: "help-body",
                class: { hidden: _vm.model.helps.homeImageSP.hidden },
              },
              [_vm._m(1)]
            ),
          ]),
        ]),
        _c("div", { staticClass: "body" }, [
          _c("div", { staticClass: "fileupload" }, [
            _c("div", { staticClass: "left" }, [
              _c("div", { staticClass: "image" }, [
                _vm.model.homeImageSP.base64Src != null
                  ? _c("img", {
                      attrs: { src: _vm.model.homeImageSP.base64Src },
                    })
                  : _vm._e(),
                _vm.model.homeImageSP.defaultFileImageUrl != null
                  ? _c("img", {
                      attrs: { src: _vm.model.homeImageSP.defaultFileImageUrl },
                    })
                  : _vm._e(),
                !_vm.model.homeImageSP.isSetSrc
                  ? _c("span", [_vm._v("ファイルを選択してください")])
                  : _vm._e(),
              ]),
            ]),
            _c("div", { staticClass: "right" }, [
              _vm.model.homeImageSP.loacalFilePath != null
                ? _c(
                    "span",
                    { staticClass: "path" },
                    [
                      _vm._v(_vm._s(_vm.model.homeImageSP.loacalFilePath)),
                      _c("fa-icon", {
                        attrs: { icon: "times" },
                        on: {
                          click: function ($event) {
                            return _vm.deleteImage(
                              _vm.model.homeImageSP,
                              "model.homeImageSP"
                            )
                          },
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.elementClick("model.homeImageSP")
                    },
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "icon" },
                    [_c("fa-icon", { attrs: { icon: "folder-open" } })],
                    1
                  ),
                  _c("span", [_vm._v("ファイルを選択")]),
                ]
              ),
              _c("input", {
                attrs: {
                  type: "file",
                  id: "model.homeImageSP",
                  accept: "image/*",
                },
                on: {
                  input: function ($event) {
                    return _vm.changeImage(
                      $event,
                      _vm.model.homeImageSP,
                      "homeImageSP"
                    )
                  },
                },
              }),
            ]),
          ]),
          _c(
            "div",
            {
              staticClass: "errors",
              class: { hidden: !_vm.model.homeImageSP.isError },
            },
            _vm._l(_vm.model.homeImageSP.errors, function (item1) {
              return _c(
                "div",
                {
                  key: _vm.model.homeImageSP.errors.indexOf(item1),
                  staticClass: "error",
                },
                [
                  _c(
                    "div",
                    { staticClass: "icon" },
                    [_c("fa-icon", { attrs: { icon: "exclamation-circle" } })],
                    1
                  ),
                  _c("span", [_vm._v(_vm._s(item1.message))]),
                ]
              )
            }),
            0
          ),
        ]),
      ]),
      _c("hr"),
      _c("div", { staticClass: "formGroupV2" }, [
        _c("div", { staticClass: "header" }, [
          _c("div", { staticClass: "row1" }, [
            _c("span", { staticClass: "title" }, [
              _vm._v("キャッチコピータイトル"),
            ]),
            _c(
              "span",
              {
                staticClass: "help-button",
                on: {
                  click: function ($event) {
                    return _vm.model.helps.catchCopyTitle.switch()
                  },
                },
              },
              [_c("fa-icon", { attrs: { icon: ["far", "question-circle"] } })],
              1
            ),
          ]),
          _c("div", { staticClass: "row2" }, [
            _c(
              "div",
              {
                staticClass: "help-body",
                class: { hidden: _vm.model.helps.catchCopyTitle.hidden },
              },
              [_vm._m(2)]
            ),
          ]),
        ]),
        _c("div", { staticClass: "body" }, [
          _c("textarea", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.model.catchCopyTitle.value,
                expression: "model.catchCopyTitle.value",
              },
            ],
            staticClass: "height-row-2",
            class: { error: _vm.model.catchCopyTitle.isError },
            attrs: { cols: "60" },
            domProps: { value: _vm.model.catchCopyTitle.value },
            on: {
              change: function ($event) {
                return _vm.validationCheck("catchCopyTitle")
              },
              input: [
                function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(
                    _vm.model.catchCopyTitle,
                    "value",
                    $event.target.value
                  )
                },
                function ($event) {
                  return _vm.validationCheck("catchCopyTitle", true)
                },
              ],
            },
          }),
          _c(
            "div",
            {
              staticClass: "errors",
              class: { hidden: !_vm.model.catchCopyTitle.isError },
            },
            _vm._l(_vm.model.catchCopyTitle.errors, function (item1) {
              return _c(
                "div",
                {
                  key: _vm.model.catchCopyTitle.errors.indexOf(item1),
                  staticClass: "error",
                },
                [
                  _c(
                    "div",
                    { staticClass: "icon" },
                    [_c("fa-icon", { attrs: { icon: "exclamation-circle" } })],
                    1
                  ),
                  _c("span", [_vm._v(_vm._s(item1.message))]),
                ]
              )
            }),
            0
          ),
        ]),
      ]),
      _c("hr"),
      _c("div", { staticClass: "formGroupV2" }, [
        _c("div", { staticClass: "header" }, [
          _c("div", { staticClass: "row1" }, [
            _c("span", { staticClass: "title" }, [
              _vm._v("キャッチコピーボディー"),
            ]),
            _c(
              "span",
              {
                staticClass: "help-button",
                on: {
                  click: function ($event) {
                    return _vm.model.helps.catchCopyBody.switch()
                  },
                },
              },
              [_c("fa-icon", { attrs: { icon: ["far", "question-circle"] } })],
              1
            ),
          ]),
          _c("div", { staticClass: "row2" }, [
            _c(
              "div",
              {
                staticClass: "help-body",
                class: { hidden: _vm.model.helps.catchCopyBody.hidden },
              },
              [_vm._m(3)]
            ),
          ]),
        ]),
        _c("div", { staticClass: "body" }, [
          _c("textarea", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.model.catchCopyBody.value,
                expression: "model.catchCopyBody.value",
              },
            ],
            staticClass: "height-row-5",
            class: { error: _vm.model.catchCopyBody.isError },
            attrs: { cols: "60" },
            domProps: { value: _vm.model.catchCopyBody.value },
            on: {
              change: function ($event) {
                return _vm.validationCheck("catchCopyBody")
              },
              input: [
                function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(
                    _vm.model.catchCopyBody,
                    "value",
                    $event.target.value
                  )
                },
                function ($event) {
                  return _vm.validationCheck("catchCopyBody", true)
                },
              ],
            },
          }),
          _c(
            "div",
            {
              staticClass: "errors",
              class: { hidden: !_vm.model.catchCopyBody.isError },
            },
            _vm._l(_vm.model.catchCopyBody.errors, function (item1) {
              return _c(
                "div",
                {
                  key: _vm.model.catchCopyBody.errors.indexOf(item1),
                  staticClass: "error",
                },
                [
                  _c(
                    "div",
                    { staticClass: "icon" },
                    [_c("fa-icon", { attrs: { icon: "exclamation-circle" } })],
                    1
                  ),
                  _c("span", [_vm._v(_vm._s(item1.message))]),
                ]
              )
            }),
            0
          ),
        ]),
      ]),
      _c("hr"),
      _c("div", { staticClass: "formGroupV2" }, [
        _c("div", { staticClass: "header" }, [
          _c("div", { staticClass: "row1" }, [
            _c("span", { staticClass: "title" }, [
              _vm._v("サインアップボタンテキスト"),
            ]),
            _c(
              "span",
              {
                staticClass: "help-button",
                on: {
                  click: function ($event) {
                    return _vm.model.helps.signUpButton.switch()
                  },
                },
              },
              [_c("fa-icon", { attrs: { icon: ["far", "question-circle"] } })],
              1
            ),
          ]),
          _c("div", { staticClass: "row2" }, [
            _c(
              "div",
              {
                staticClass: "help-body",
                class: { hidden: _vm.model.helps.signUpButton.hidden },
              },
              [_vm._m(4)]
            ),
          ]),
        ]),
        _c("div", { staticClass: "body" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.model.signUpButton.value,
                expression: "model.signUpButton.value",
              },
            ],
            staticClass: "width-300",
            class: { error: _vm.model.signUpButton.isError },
            attrs: { type: "text" },
            domProps: { value: _vm.model.signUpButton.value },
            on: {
              change: function ($event) {
                return _vm.validationCheck("signUpButton")
              },
              input: [
                function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.model.signUpButton, "value", $event.target.value)
                },
                function ($event) {
                  return _vm.validationCheck("signUpButton", true)
                },
              ],
            },
          }),
          _c(
            "div",
            {
              staticClass: "errors",
              class: { hidden: !_vm.model.signUpButton.isError },
            },
            _vm._l(_vm.model.signUpButton.errors, function (item1) {
              return _c(
                "div",
                {
                  key: _vm.model.signUpButton.errors.indexOf(item1),
                  staticClass: "error",
                },
                [
                  _c(
                    "div",
                    { staticClass: "icon" },
                    [_c("fa-icon", { attrs: { icon: "exclamation-circle" } })],
                    1
                  ),
                  _c("span", [_vm._v(_vm._s(item1.message))]),
                ]
              )
            }),
            0
          ),
        ]),
      ]),
      _c("hr"),
      _c("div", { staticClass: "formGroupV2" }, [
        _c("div", { staticClass: "header" }, [
          _c("div", { staticClass: "row1" }, [
            _c("span", { staticClass: "title" }, [
              _vm._v("キャッチ文言表示位置（PC）"),
            ]),
            _c(
              "span",
              {
                staticClass: "help-button",
                on: {
                  click: function ($event) {
                    return _vm.model.helps.catchPositionPC.switch()
                  },
                },
              },
              [_c("fa-icon", { attrs: { icon: ["far", "question-circle"] } })],
              1
            ),
          ]),
          _c("div", { staticClass: "row2" }, [
            _c(
              "div",
              {
                staticClass: "help-body",
                class: { hidden: _vm.model.helps.catchPositionPC.hidden },
              },
              [_vm._m(5)]
            ),
          ]),
        ]),
        _c("div", { staticClass: "body" }, [
          _c("table", { staticClass: "catchPosition" }, [
            _c("tr", [
              _c("td", [
                _c("label", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.model.catchPositionPC.value,
                        expression: "model.catchPositionPC.value",
                      },
                    ],
                    attrs: {
                      name: "catchPositionPC",
                      type: "radio",
                      value: "left-top",
                    },
                    domProps: {
                      checked: _vm._q(
                        _vm.model.catchPositionPC.value,
                        "left-top"
                      ),
                    },
                    on: {
                      change: function ($event) {
                        return _vm.$set(
                          _vm.model.catchPositionPC,
                          "value",
                          "left-top"
                        )
                      },
                    },
                  }),
                ]),
              ]),
              _c("td", [
                _c("label", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.model.catchPositionPC.value,
                        expression: "model.catchPositionPC.value",
                      },
                    ],
                    attrs: {
                      name: "catchPositionPC",
                      type: "radio",
                      value: "center-top",
                    },
                    domProps: {
                      checked: _vm._q(
                        _vm.model.catchPositionPC.value,
                        "center-top"
                      ),
                    },
                    on: {
                      change: function ($event) {
                        return _vm.$set(
                          _vm.model.catchPositionPC,
                          "value",
                          "center-top"
                        )
                      },
                    },
                  }),
                ]),
              ]),
              _c("td", [
                _c("label", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.model.catchPositionPC.value,
                        expression: "model.catchPositionPC.value",
                      },
                    ],
                    attrs: {
                      name: "catchPositionPC",
                      type: "radio",
                      value: "right-top",
                    },
                    domProps: {
                      checked: _vm._q(
                        _vm.model.catchPositionPC.value,
                        "right-top"
                      ),
                    },
                    on: {
                      change: function ($event) {
                        return _vm.$set(
                          _vm.model.catchPositionPC,
                          "value",
                          "right-top"
                        )
                      },
                    },
                  }),
                ]),
              ]),
            ]),
            _c("tr", [
              _c("td", [
                _c("label", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.model.catchPositionPC.value,
                        expression: "model.catchPositionPC.value",
                      },
                    ],
                    attrs: {
                      name: "catchPositionPC",
                      type: "radio",
                      value: "left-center",
                    },
                    domProps: {
                      checked: _vm._q(
                        _vm.model.catchPositionPC.value,
                        "left-center"
                      ),
                    },
                    on: {
                      change: function ($event) {
                        return _vm.$set(
                          _vm.model.catchPositionPC,
                          "value",
                          "left-center"
                        )
                      },
                    },
                  }),
                ]),
              ]),
              _c("td", [
                _c("label", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.model.catchPositionPC.value,
                        expression: "model.catchPositionPC.value",
                      },
                    ],
                    attrs: {
                      name: "catchPositionPC",
                      type: "radio",
                      value: "center-center",
                    },
                    domProps: {
                      checked: _vm._q(
                        _vm.model.catchPositionPC.value,
                        "center-center"
                      ),
                    },
                    on: {
                      change: function ($event) {
                        return _vm.$set(
                          _vm.model.catchPositionPC,
                          "value",
                          "center-center"
                        )
                      },
                    },
                  }),
                ]),
              ]),
              _c("td", [
                _c("label", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.model.catchPositionPC.value,
                        expression: "model.catchPositionPC.value",
                      },
                    ],
                    attrs: {
                      name: "catchPositionPC",
                      type: "radio",
                      value: "right-center",
                    },
                    domProps: {
                      checked: _vm._q(
                        _vm.model.catchPositionPC.value,
                        "right-center"
                      ),
                    },
                    on: {
                      change: function ($event) {
                        return _vm.$set(
                          _vm.model.catchPositionPC,
                          "value",
                          "right-center"
                        )
                      },
                    },
                  }),
                ]),
              ]),
            ]),
            _c("tr", [
              _c("td", [
                _c("label", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.model.catchPositionPC.value,
                        expression: "model.catchPositionPC.value",
                      },
                    ],
                    attrs: {
                      name: "catchPositionPC",
                      type: "radio",
                      value: "left-bottom",
                    },
                    domProps: {
                      checked: _vm._q(
                        _vm.model.catchPositionPC.value,
                        "left-bottom"
                      ),
                    },
                    on: {
                      change: function ($event) {
                        return _vm.$set(
                          _vm.model.catchPositionPC,
                          "value",
                          "left-bottom"
                        )
                      },
                    },
                  }),
                ]),
              ]),
              _c("td", [
                _c("label", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.model.catchPositionPC.value,
                        expression: "model.catchPositionPC.value",
                      },
                    ],
                    attrs: {
                      name: "catchPositionPC",
                      type: "radio",
                      value: "center-bottom",
                    },
                    domProps: {
                      checked: _vm._q(
                        _vm.model.catchPositionPC.value,
                        "center-bottom"
                      ),
                    },
                    on: {
                      change: function ($event) {
                        return _vm.$set(
                          _vm.model.catchPositionPC,
                          "value",
                          "center-bottom"
                        )
                      },
                    },
                  }),
                ]),
              ]),
              _c("td", [
                _c("label", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.model.catchPositionPC.value,
                        expression: "model.catchPositionPC.value",
                      },
                    ],
                    attrs: {
                      name: "catchPositionPC",
                      type: "radio",
                      value: "right-bottom",
                    },
                    domProps: {
                      checked: _vm._q(
                        _vm.model.catchPositionPC.value,
                        "right-bottom"
                      ),
                    },
                    on: {
                      change: function ($event) {
                        return _vm.$set(
                          _vm.model.catchPositionPC,
                          "value",
                          "right-bottom"
                        )
                      },
                    },
                  }),
                ]),
              ]),
            ]),
          ]),
          _c(
            "div",
            {
              staticClass: "errors",
              class: { hidden: !_vm.model.catchPositionPC.isError },
            },
            _vm._l(_vm.model.catchPositionPC.errors, function (item1) {
              return _c(
                "div",
                {
                  key: _vm.model.catchPositionPC.errors.indexOf(item1),
                  staticClass: "error",
                },
                [
                  _c(
                    "div",
                    { staticClass: "icon" },
                    [_c("fa-icon", { attrs: { icon: "exclamation-circle" } })],
                    1
                  ),
                  _c("span", [_vm._v(_vm._s(item1.message))]),
                ]
              )
            }),
            0
          ),
        ]),
      ]),
      _c("hr"),
      _c("div", { staticClass: "formGroupV2" }, [
        _c("div", { staticClass: "header" }, [
          _c("div", { staticClass: "row1" }, [
            _c("span", { staticClass: "title" }, [
              _vm._v("キャッチ文言文字カラー（PC）"),
            ]),
            _c(
              "span",
              {
                staticClass: "help-button",
                on: {
                  click: function ($event) {
                    return _vm.model.helps.homeImageTextColorPC.switch()
                  },
                },
              },
              [_c("fa-icon", { attrs: { icon: ["far", "question-circle"] } })],
              1
            ),
          ]),
          _c("div", { staticClass: "row2" }, [
            _c(
              "div",
              {
                staticClass: "help-body",
                class: { hidden: _vm.model.helps.homeImageTextColorPC.hidden },
              },
              [_vm._m(6)]
            ),
          ]),
        ]),
        _c("div", { staticClass: "body" }, [
          _c("div", { staticClass: "radioForm1Line" }, [
            _c("label", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.model.homeImageTextColorPC.value,
                    expression: "model.homeImageTextColorPC.value",
                  },
                ],
                attrs: {
                  name: "homeImageTextColorPC",
                  type: "radio",
                  value: "white",
                },
                domProps: {
                  checked: _vm._q(
                    _vm.model.homeImageTextColorPC.value,
                    "white"
                  ),
                },
                on: {
                  change: function ($event) {
                    return _vm.$set(
                      _vm.model.homeImageTextColorPC,
                      "value",
                      "white"
                    )
                  },
                },
              }),
              _vm._v("ホワイト"),
            ]),
            _c("label", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.model.homeImageTextColorPC.value,
                    expression: "model.homeImageTextColorPC.value",
                  },
                ],
                attrs: {
                  name: "homeImageTextColorPC",
                  type: "radio",
                  value: "black",
                },
                domProps: {
                  checked: _vm._q(
                    _vm.model.homeImageTextColorPC.value,
                    "black"
                  ),
                },
                on: {
                  change: function ($event) {
                    return _vm.$set(
                      _vm.model.homeImageTextColorPC,
                      "value",
                      "black"
                    )
                  },
                },
              }),
              _vm._v("ブラック"),
            ]),
          ]),
          _c(
            "div",
            {
              staticClass: "errors",
              class: { hidden: !_vm.model.homeImageTextColorPC.isError },
            },
            _vm._l(_vm.model.homeImageTextColorPC.errors, function (item1) {
              return _c(
                "div",
                {
                  key: _vm.model.homeImageTextColorPC.errors.indexOf(item1),
                  staticClass: "error",
                },
                [
                  _c(
                    "div",
                    { staticClass: "icon" },
                    [_c("fa-icon", { attrs: { icon: "exclamation-circle" } })],
                    1
                  ),
                  _c("span", [_vm._v(_vm._s(item1.message))]),
                ]
              )
            }),
            0
          ),
        ]),
      ]),
      _c("hr"),
      _c("div", { staticClass: "formGroupV2" }, [
        _c("div", { staticClass: "header" }, [
          _c("div", { staticClass: "row1" }, [
            _c("span", { staticClass: "title" }, [
              _vm._v("キャッチ文言表示位置（SP）"),
            ]),
            _c(
              "span",
              {
                staticClass: "help-button",
                on: {
                  click: function ($event) {
                    return _vm.model.helps.catchPositionSP.switch()
                  },
                },
              },
              [_c("fa-icon", { attrs: { icon: ["far", "question-circle"] } })],
              1
            ),
          ]),
          _c("div", { staticClass: "row2" }, [
            _c(
              "div",
              {
                staticClass: "help-body",
                class: { hidden: _vm.model.helps.catchPositionSP.hidden },
              },
              [_vm._m(7)]
            ),
          ]),
        ]),
        _c("div", { staticClass: "body" }, [
          _c("table", { staticClass: "catchPosition" }, [
            _c("tr", [
              _c("td", [
                _c("label", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.model.catchPositionSP.value,
                        expression: "model.catchPositionSP.value",
                      },
                    ],
                    attrs: {
                      name: "catchPositionSP",
                      type: "radio",
                      value: "left-top",
                    },
                    domProps: {
                      checked: _vm._q(
                        _vm.model.catchPositionSP.value,
                        "left-top"
                      ),
                    },
                    on: {
                      change: function ($event) {
                        return _vm.$set(
                          _vm.model.catchPositionSP,
                          "value",
                          "left-top"
                        )
                      },
                    },
                  }),
                ]),
              ]),
              _c("td", [
                _c("label", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.model.catchPositionSP.value,
                        expression: "model.catchPositionSP.value",
                      },
                    ],
                    attrs: {
                      name: "catchPositionSP",
                      type: "radio",
                      value: "center-top",
                    },
                    domProps: {
                      checked: _vm._q(
                        _vm.model.catchPositionSP.value,
                        "center-top"
                      ),
                    },
                    on: {
                      change: function ($event) {
                        return _vm.$set(
                          _vm.model.catchPositionSP,
                          "value",
                          "center-top"
                        )
                      },
                    },
                  }),
                ]),
              ]),
              _c("td", [
                _c("label", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.model.catchPositionSP.value,
                        expression: "model.catchPositionSP.value",
                      },
                    ],
                    attrs: {
                      name: "catchPositionSP",
                      type: "radio",
                      value: "right-top",
                    },
                    domProps: {
                      checked: _vm._q(
                        _vm.model.catchPositionSP.value,
                        "right-top"
                      ),
                    },
                    on: {
                      change: function ($event) {
                        return _vm.$set(
                          _vm.model.catchPositionSP,
                          "value",
                          "right-top"
                        )
                      },
                    },
                  }),
                ]),
              ]),
            ]),
            _c("tr", [
              _c("td", [
                _c("label", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.model.catchPositionSP.value,
                        expression: "model.catchPositionSP.value",
                      },
                    ],
                    attrs: {
                      name: "catchPositionSP",
                      type: "radio",
                      value: "left-center",
                    },
                    domProps: {
                      checked: _vm._q(
                        _vm.model.catchPositionSP.value,
                        "left-center"
                      ),
                    },
                    on: {
                      change: function ($event) {
                        return _vm.$set(
                          _vm.model.catchPositionSP,
                          "value",
                          "left-center"
                        )
                      },
                    },
                  }),
                ]),
              ]),
              _c("td", [
                _c("label", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.model.catchPositionSP.value,
                        expression: "model.catchPositionSP.value",
                      },
                    ],
                    attrs: {
                      name: "catchPositionSP",
                      type: "radio",
                      value: "center-center",
                    },
                    domProps: {
                      checked: _vm._q(
                        _vm.model.catchPositionSP.value,
                        "center-center"
                      ),
                    },
                    on: {
                      change: function ($event) {
                        return _vm.$set(
                          _vm.model.catchPositionSP,
                          "value",
                          "center-center"
                        )
                      },
                    },
                  }),
                ]),
              ]),
              _c("td", [
                _c("label", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.model.catchPositionSP.value,
                        expression: "model.catchPositionSP.value",
                      },
                    ],
                    attrs: {
                      name: "catchPositionSP",
                      type: "radio",
                      value: "right-center",
                    },
                    domProps: {
                      checked: _vm._q(
                        _vm.model.catchPositionSP.value,
                        "right-center"
                      ),
                    },
                    on: {
                      change: function ($event) {
                        return _vm.$set(
                          _vm.model.catchPositionSP,
                          "value",
                          "right-center"
                        )
                      },
                    },
                  }),
                ]),
              ]),
            ]),
            _c("tr", [
              _c("td", [
                _c("label", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.model.catchPositionSP.value,
                        expression: "model.catchPositionSP.value",
                      },
                    ],
                    attrs: {
                      name: "catchPositionSP",
                      type: "radio",
                      value: "left-bottom",
                    },
                    domProps: {
                      checked: _vm._q(
                        _vm.model.catchPositionSP.value,
                        "left-bottom"
                      ),
                    },
                    on: {
                      change: function ($event) {
                        return _vm.$set(
                          _vm.model.catchPositionSP,
                          "value",
                          "left-bottom"
                        )
                      },
                    },
                  }),
                ]),
              ]),
              _c("td", [
                _c("label", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.model.catchPositionSP.value,
                        expression: "model.catchPositionSP.value",
                      },
                    ],
                    attrs: {
                      name: "catchPositionSP",
                      type: "radio",
                      value: "center-bottom",
                    },
                    domProps: {
                      checked: _vm._q(
                        _vm.model.catchPositionSP.value,
                        "center-bottom"
                      ),
                    },
                    on: {
                      change: function ($event) {
                        return _vm.$set(
                          _vm.model.catchPositionSP,
                          "value",
                          "center-bottom"
                        )
                      },
                    },
                  }),
                ]),
              ]),
              _c("td", [
                _c("label", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.model.catchPositionSP.value,
                        expression: "model.catchPositionSP.value",
                      },
                    ],
                    attrs: {
                      name: "catchPositionSP",
                      type: "radio",
                      value: "right-bottom",
                    },
                    domProps: {
                      checked: _vm._q(
                        _vm.model.catchPositionSP.value,
                        "right-bottom"
                      ),
                    },
                    on: {
                      change: function ($event) {
                        return _vm.$set(
                          _vm.model.catchPositionSP,
                          "value",
                          "right-bottom"
                        )
                      },
                    },
                  }),
                ]),
              ]),
            ]),
          ]),
          _c(
            "div",
            {
              staticClass: "errors",
              class: { hidden: !_vm.model.catchPositionSP.isError },
            },
            _vm._l(_vm.model.catchPositionSP.errors, function (item1) {
              return _c(
                "div",
                {
                  key: _vm.model.catchPositionSP.errors.indexOf(item1),
                  staticClass: "error",
                },
                [
                  _c(
                    "div",
                    { staticClass: "icon" },
                    [_c("fa-icon", { attrs: { icon: "exclamation-circle" } })],
                    1
                  ),
                  _c("span", [_vm._v(_vm._s(item1.message))]),
                ]
              )
            }),
            0
          ),
        ]),
      ]),
      _c("hr"),
      _c("div", { staticClass: "formGroupV2" }, [
        _c("div", { staticClass: "header" }, [
          _c("div", { staticClass: "row1" }, [
            _c("span", { staticClass: "title" }, [
              _vm._v("キャッチ文言文字カラー（SP）"),
            ]),
            _c(
              "span",
              {
                staticClass: "help-button",
                on: {
                  click: function ($event) {
                    return _vm.model.helps.homeImageTextColorSP.switch()
                  },
                },
              },
              [_c("fa-icon", { attrs: { icon: ["far", "question-circle"] } })],
              1
            ),
          ]),
          _c("div", { staticClass: "row2" }, [
            _c(
              "div",
              {
                staticClass: "help-body",
                class: { hidden: _vm.model.helps.homeImageTextColorSP.hidden },
              },
              [_vm._m(8)]
            ),
          ]),
        ]),
        _c("div", { staticClass: "body" }, [
          _c("div", { staticClass: "radioForm1Line" }, [
            _c("label", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.model.homeImageTextColorSP.value,
                    expression: "model.homeImageTextColorSP.value",
                  },
                ],
                attrs: {
                  name: "homeImageTextColorSP",
                  type: "radio",
                  value: "white",
                },
                domProps: {
                  checked: _vm._q(
                    _vm.model.homeImageTextColorSP.value,
                    "white"
                  ),
                },
                on: {
                  change: function ($event) {
                    return _vm.$set(
                      _vm.model.homeImageTextColorSP,
                      "value",
                      "white"
                    )
                  },
                },
              }),
              _vm._v("ホワイト"),
            ]),
            _c("label", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.model.homeImageTextColorSP.value,
                    expression: "model.homeImageTextColorSP.value",
                  },
                ],
                attrs: {
                  name: "homeImageTextColorSP",
                  type: "radio",
                  value: "black",
                },
                domProps: {
                  checked: _vm._q(
                    _vm.model.homeImageTextColorSP.value,
                    "black"
                  ),
                },
                on: {
                  change: function ($event) {
                    return _vm.$set(
                      _vm.model.homeImageTextColorSP,
                      "value",
                      "black"
                    )
                  },
                },
              }),
              _vm._v("ブラック"),
            ]),
          ]),
          _c(
            "div",
            {
              staticClass: "errors",
              class: { hidden: !_vm.model.homeImageTextColorSP.isError },
            },
            _vm._l(_vm.model.homeImageTextColorSP.errors, function (item1) {
              return _c(
                "div",
                {
                  key: _vm.model.homeImageTextColorSP.errors.indexOf(item1),
                  staticClass: "error",
                },
                [
                  _c(
                    "div",
                    { staticClass: "icon" },
                    [_c("fa-icon", { attrs: { icon: "exclamation-circle" } })],
                    1
                  ),
                  _c("span", [_vm._v(_vm._s(item1.message))]),
                ]
              )
            }),
            0
          ),
        ]),
      ]),
      _c("hr"),
      _c("div", { staticClass: "formGroupV2" }, [
        _c("div", { staticClass: "header" }, [
          _c("div", { staticClass: "row1" }, [
            _c("span", { staticClass: "title" }, [_vm._v("PRポイント")]),
            _c(
              "span",
              {
                staticClass: "help-button",
                on: {
                  click: function ($event) {
                    return _vm.model.helps.point.switch()
                  },
                },
              },
              [_c("fa-icon", { attrs: { icon: ["far", "question-circle"] } })],
              1
            ),
          ]),
          _c("div", { staticClass: "row2" }, [
            _c(
              "div",
              {
                staticClass: "help-body",
                class: { hidden: _vm.model.helps.point.hidden },
              },
              [_vm._m(9)]
            ),
          ]),
        ]),
        _c("div", { staticClass: "body" }, [
          _c("div", { staticClass: "point" }, [
            _c(
              "div",
              { staticClass: "left" },
              [
                _c(
                  "transition-group",
                  { attrs: { name: "point-list", tag: "ul" } },
                  _vm._l(_vm.model.point.items, function (item1) {
                    return _c(
                      "li",
                      {
                        key: item1.key,
                        staticClass: "active",
                        class: { active: item1.isSelected },
                        staticStyle: {
                          "{\n                  transition": "all 1s",
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "left",
                            on: {
                              click: function ($event) {
                                return _vm.selectPoint(item1)
                              },
                            },
                          },
                          [
                            item1.isSelected
                              ? _c("fa-icon", {
                                  attrs: { icon: ["far", "dot-circle"] },
                                })
                              : _c("fa-icon", {
                                  attrs: { icon: ["far", "circle"] },
                                }),
                            item1.title.value.length > 0
                              ? _c("span", [_vm._v(_vm._s(item1.title.value))])
                              : _c("span", [_vm._v("[未設定]")]),
                            item1.isError
                              ? _c("fa-icon", {
                                  staticClass: "error",
                                  attrs: { icon: "exclamation-circle" },
                                })
                              : _vm._e(),
                            item1.isSelected
                              ? _c("span", { staticClass: "editing" }, [
                                  _vm._v("編集中"),
                                ])
                              : _vm._e(),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "right" }, [
                          _c(
                            "button",
                            {
                              staticClass: "updown",
                              attrs: { disabled: !item1.isUp },
                              on: {
                                click: function ($event) {
                                  return item1.up()
                                },
                              },
                            },
                            [_c("fa-icon", { attrs: { icon: "angle-up" } })],
                            1
                          ),
                          _c(
                            "button",
                            {
                              staticClass: "updown",
                              attrs: { disabled: !item1.isDown },
                              on: {
                                click: function ($event) {
                                  return item1.down()
                                },
                              },
                            },
                            [_c("fa-icon", { attrs: { icon: "angle-down" } })],
                            1
                          ),
                          _c(
                            "button",
                            {
                              staticClass: "delete",
                              on: {
                                click: function ($event) {
                                  return _vm.deletePoint(item1)
                                },
                              },
                            },
                            [_c("fa-icon", { attrs: { icon: "times" } })],
                            1
                          ),
                        ]),
                      ]
                    )
                  }),
                  0
                ),
                _c(
                  "button",
                  {
                    attrs: { disabled: _vm.model.point.isFull },
                    on: { click: _vm.addPoint },
                  },
                  [_c("fa-icon", { attrs: { icon: "plus" } })],
                  1
                ),
              ],
              1
            ),
            _vm.model.point.isSelected
              ? _c("div", { staticClass: "right" }, [
                  _c("div", { staticClass: "body" }, [
                    _c("div", { staticClass: "formGroupV2" }, [
                      _c("div", { staticClass: "header" }, [
                        _c("div", { staticClass: "row1" }, [
                          _c("span", { staticClass: "title" }, [
                            _vm._v("タイトル"),
                          ]),
                          _c(
                            "span",
                            {
                              staticClass: "help-button",
                              on: {
                                click: function ($event) {
                                  return _vm.model.helps.pointTitle.switch()
                                },
                              },
                            },
                            [
                              _c("fa-icon", {
                                attrs: { icon: ["far", "question-circle"] },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _c("div", { staticClass: "row2" }, [
                          _c(
                            "div",
                            {
                              staticClass: "help-body",
                              class: {
                                hidden: _vm.model.helps.pointTitle.hidden,
                              },
                            },
                            [_vm._m(10)]
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "body" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.model.point.selectedItem.title.value,
                              expression:
                                "model.point.selectedItem.title.value",
                            },
                          ],
                          staticClass: "width-300",
                          class: {
                            error: _vm.model.point.selectedItem.title.isError,
                          },
                          attrs: { type: "text" },
                          domProps: {
                            value: _vm.model.point.selectedItem.title.value,
                          },
                          on: {
                            change: function ($event) {
                              _vm.validationCheck(
                                "point.items[" +
                                  _vm.model.point.items.indexOf(
                                    _vm.model.point.selectedItem
                                  ) +
                                  "].title"
                              )
                            },
                            input: [
                              function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.model.point.selectedItem.title,
                                  "value",
                                  $event.target.value
                                )
                              },
                              function ($event) {
                                _vm.validationCheck(
                                  "point.items[" +
                                    _vm.model.point.items.indexOf(
                                      _vm.model.point.selectedItem
                                    ) +
                                    "].title",
                                  true
                                )
                              },
                            ],
                          },
                        }),
                        _c(
                          "div",
                          {
                            staticClass: "errors",
                            class: {
                              hidden:
                                !_vm.model.point.selectedItem.title.isError,
                            },
                          },
                          _vm._l(
                            _vm.model.point.selectedItem.title.errors,
                            function (item2) {
                              return _c(
                                "div",
                                {
                                  key: _vm.model.point.selectedItem.title.errors.indexOf(
                                    item2
                                  ),
                                  staticClass: "error",
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "icon" },
                                    [
                                      _c("fa-icon", {
                                        attrs: { icon: "exclamation-circle" },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c("span", [_vm._v(_vm._s(item2.message))]),
                                ]
                              )
                            }
                          ),
                          0
                        ),
                      ]),
                    ]),
                    _c("hr"),
                    _c("div", { staticClass: "formGroupV2" }, [
                      _c("div", { staticClass: "header" }, [
                        _c("div", { staticClass: "row1" }, [
                          _c("span", { staticClass: "title" }, [
                            _vm._v("ボディー"),
                          ]),
                          _c(
                            "span",
                            {
                              staticClass: "help-button",
                              on: {
                                click: function ($event) {
                                  return _vm.model.helps.pointBody.switch()
                                },
                              },
                            },
                            [
                              _c("fa-icon", {
                                attrs: { icon: ["far", "question-circle"] },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _c("div", { staticClass: "row2" }, [
                          _c(
                            "div",
                            {
                              staticClass: "help-body",
                              class: {
                                hidden: _vm.model.helps.pointBody.hidden,
                              },
                            },
                            [_vm._m(11)]
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "body" }, [
                        _c("textarea", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.model.point.selectedItem.body.value,
                              expression: "model.point.selectedItem.body.value",
                            },
                          ],
                          staticClass: "height-row-5",
                          class: {
                            error: _vm.model.point.selectedItem.body.isError,
                          },
                          attrs: { cols: "60" },
                          domProps: {
                            value: _vm.model.point.selectedItem.body.value,
                          },
                          on: {
                            change: function ($event) {
                              _vm.validationCheck(
                                "point.items[" +
                                  _vm.model.point.items.indexOf(
                                    _vm.model.point.selectedItem
                                  ) +
                                  "].body"
                              )
                            },
                            input: [
                              function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.model.point.selectedItem.body,
                                  "value",
                                  $event.target.value
                                )
                              },
                              function ($event) {
                                _vm.validationCheck(
                                  "point.items[" +
                                    _vm.model.point.items.indexOf(
                                      _vm.model.point.selectedItem
                                    ) +
                                    "].body",
                                  true
                                )
                              },
                            ],
                          },
                        }),
                        _c(
                          "div",
                          {
                            staticClass: "errors",
                            class: {
                              hidden:
                                !_vm.model.point.selectedItem.body.isError,
                            },
                          },
                          _vm._l(
                            _vm.model.point.selectedItem.body.errors,
                            function (item2) {
                              return _c(
                                "div",
                                {
                                  key: _vm.model.point.selectedItem.body.errors.indexOf(
                                    item2
                                  ),
                                  staticClass: "error",
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "icon" },
                                    [
                                      _c("fa-icon", {
                                        attrs: { icon: "exclamation-circle" },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c("span", [_vm._v(_vm._s(item2.message))]),
                                ]
                              )
                            }
                          ),
                          0
                        ),
                      ]),
                    ]),
                    _c("hr"),
                    _c("div", { staticClass: "formGroupV2" }, [
                      _c("div", { staticClass: "header" }, [
                        _c("div", { staticClass: "row1" }, [
                          _c("span", { staticClass: "title" }, [
                            _vm._v("アイコン"),
                          ]),
                          _c(
                            "span",
                            {
                              staticClass: "help-button",
                              on: {
                                click: function ($event) {
                                  return _vm.model.helps.pointIcon.switch()
                                },
                              },
                            },
                            [
                              _c("fa-icon", {
                                attrs: { icon: ["far", "question-circle"] },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _c("div", { staticClass: "row2" }, [
                          _c(
                            "div",
                            {
                              staticClass: "help-body",
                              class: {
                                hidden: _vm.model.helps.pointIcon.hidden,
                              },
                            },
                            [_vm._m(12)]
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "body" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.model.point.selectedItem.icon.value,
                              expression: "model.point.selectedItem.icon.value",
                            },
                          ],
                          staticClass: "width-300",
                          class: {
                            error: _vm.model.point.selectedItem.icon.isError,
                          },
                          attrs: { type: "text" },
                          domProps: {
                            value: _vm.model.point.selectedItem.icon.value,
                          },
                          on: {
                            change: function ($event) {
                              _vm.validationCheck(
                                "point.items[" +
                                  _vm.model.point.items.indexOf(
                                    _vm.model.point.selectedItem
                                  ) +
                                  "].icon"
                              )
                            },
                            input: [
                              function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.model.point.selectedItem.icon,
                                  "value",
                                  $event.target.value
                                )
                              },
                              function ($event) {
                                _vm.validationCheck(
                                  "point.items[" +
                                    _vm.model.point.items.indexOf(
                                      _vm.model.point.selectedItem
                                    ) +
                                    "].icon",
                                  true
                                )
                              },
                            ],
                          },
                        }),
                        _c(
                          "div",
                          {
                            staticClass: "errors",
                            class: {
                              hidden:
                                !_vm.model.point.selectedItem.icon.isError,
                            },
                          },
                          _vm._l(
                            _vm.model.point.selectedItem.icon.errors,
                            function (item2) {
                              return _c(
                                "div",
                                {
                                  key: _vm.model.point.selectedItem.icon.errors.indexOf(
                                    item2
                                  ),
                                  staticClass: "error",
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "icon" },
                                    [
                                      _c("fa-icon", {
                                        attrs: { icon: "exclamation-circle" },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c("span", [_vm._v(_vm._s(item2.message))]),
                                ]
                              )
                            }
                          ),
                          0
                        ),
                      ]),
                    ]),
                    _c("hr"),
                    _c("div", { staticClass: "formGroupV2" }, [
                      _c("div", { staticClass: "header" }, [
                        _c("div", { staticClass: "row1" }, [
                          _c("span", { staticClass: "title" }, [
                            _vm._v("カラー"),
                          ]),
                          _c(
                            "span",
                            {
                              staticClass: "help-button",
                              on: {
                                click: function ($event) {
                                  return _vm.model.helps.pointColor.switch()
                                },
                              },
                            },
                            [
                              _c("fa-icon", {
                                attrs: { icon: ["far", "question-circle"] },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _c("div", { staticClass: "row2" }, [
                          _c(
                            "div",
                            {
                              staticClass: "help-body",
                              class: {
                                hidden: _vm.model.helps.pointColor.hidden,
                              },
                            },
                            [_vm._m(13)]
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "body" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.model.point.selectedItem.color.value,
                              expression:
                                "model.point.selectedItem.color.value",
                            },
                          ],
                          staticClass: "width-300",
                          class: {
                            error: _vm.model.point.selectedItem.color.isError,
                          },
                          attrs: { type: "text" },
                          domProps: {
                            value: _vm.model.point.selectedItem.color.value,
                          },
                          on: {
                            change: function ($event) {
                              _vm.validationCheck(
                                "point.items[" +
                                  _vm.model.point.items.indexOf(
                                    _vm.model.point.selectedItem
                                  ) +
                                  "].color"
                              )
                            },
                            input: [
                              function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.model.point.selectedItem.color,
                                  "value",
                                  $event.target.value
                                )
                              },
                              function ($event) {
                                _vm.validationCheck(
                                  "point.items[" +
                                    _vm.model.point.items.indexOf(
                                      _vm.model.point.selectedItem
                                    ) +
                                    "].color",
                                  true
                                )
                              },
                            ],
                          },
                        }),
                        _c(
                          "div",
                          {
                            staticClass: "errors",
                            class: {
                              hidden:
                                !_vm.model.point.selectedItem.color.isError,
                            },
                          },
                          _vm._l(
                            _vm.model.point.selectedItem.color.errors,
                            function (item2) {
                              return _c(
                                "div",
                                {
                                  key: _vm.model.point.selectedItem.color.errors.indexOf(
                                    item2
                                  ),
                                  staticClass: "error",
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "icon" },
                                    [
                                      _c("fa-icon", {
                                        attrs: { icon: "exclamation-circle" },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c("span", [_vm._v(_vm._s(item2.message))]),
                                ]
                              )
                            }
                          ),
                          0
                        ),
                      ]),
                    ]),
                  ]),
                ])
              : _c("div", { staticClass: "right not" }, [
                  _c("span", [_vm._v("アイテムを選択してください")]),
                ]),
          ]),
          _c(
            "div",
            {
              staticClass: "errors",
              class: { hidden: !_vm.model.point.isError },
            },
            _vm._l(_vm.model.point.errors, function (item1) {
              return _c(
                "div",
                {
                  key: _vm.model.point.errors.indexOf(item1),
                  staticClass: "error",
                },
                [
                  _c(
                    "div",
                    { staticClass: "icon" },
                    [_c("fa-icon", { attrs: { icon: "exclamation-circle" } })],
                    1
                  ),
                  _c("span", [_vm._v(_vm._s(item1.message))]),
                ]
              )
            }),
            0
          ),
        ]),
      ]),
      _c("hr"),
      _c("div", { staticClass: "formGroupV2" }, [
        _c("div", { staticClass: "header" }, [
          _c("div", { staticClass: "row1" }, [
            _c("span", { staticClass: "title" }, [_vm._v("プロモーション")]),
            _c(
              "span",
              {
                staticClass: "help-button",
                on: {
                  click: function ($event) {
                    return _vm.model.helps.promotion.switch()
                  },
                },
              },
              [_c("fa-icon", { attrs: { icon: ["far", "question-circle"] } })],
              1
            ),
          ]),
          _c("div", { staticClass: "row2" }, [
            _c(
              "div",
              {
                staticClass: "help-body",
                class: { hidden: _vm.model.helps.promotion.hidden },
              },
              [_vm._m(14)]
            ),
          ]),
        ]),
        _c("div", { staticClass: "body" }, [
          _c("div", { staticClass: "promotion" }, [
            _c(
              "div",
              { staticClass: "left" },
              [
                _c(
                  "transition-group",
                  { attrs: { name: "promotion-list", tag: "ul" } },
                  _vm._l(_vm.model.promotion.items, function (item1) {
                    return _c(
                      "li",
                      {
                        key: item1.key,
                        staticClass: "active",
                        class: { active: item1.isSelected },
                        staticStyle: {
                          "{\n                  transition": "all 1s",
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "left",
                            on: {
                              click: function ($event) {
                                return _vm.selectPromotion(item1)
                              },
                            },
                          },
                          [
                            item1.isSelected
                              ? _c("fa-icon", {
                                  attrs: { icon: ["far", "dot-circle"] },
                                })
                              : _c("fa-icon", {
                                  attrs: { icon: ["far", "circle"] },
                                }),
                            item1.title.value.length > 0
                              ? _c("span", [_vm._v(_vm._s(item1.title.value))])
                              : _c("span", [_vm._v("[未設定]")]),
                            item1.isError
                              ? _c("fa-icon", {
                                  staticClass: "error",
                                  attrs: { icon: "exclamation-circle" },
                                })
                              : _vm._e(),
                            item1.isSelected
                              ? _c("span", { staticClass: "editing" }, [
                                  _vm._v("編集中"),
                                ])
                              : _vm._e(),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "right" }, [
                          _c(
                            "button",
                            {
                              staticClass: "updown",
                              attrs: { disabled: !item1.isUp },
                              on: {
                                click: function ($event) {
                                  return item1.up()
                                },
                              },
                            },
                            [_c("fa-icon", { attrs: { icon: "angle-up" } })],
                            1
                          ),
                          _c(
                            "button",
                            {
                              staticClass: "updown",
                              attrs: { disabled: !item1.isDown },
                              on: {
                                click: function ($event) {
                                  return item1.down()
                                },
                              },
                            },
                            [_c("fa-icon", { attrs: { icon: "angle-down" } })],
                            1
                          ),
                          _c(
                            "button",
                            {
                              staticClass: "delete",
                              on: {
                                click: function ($event) {
                                  return _vm.deletePromotion(item1)
                                },
                              },
                            },
                            [_c("fa-icon", { attrs: { icon: "times" } })],
                            1
                          ),
                        ]),
                      ]
                    )
                  }),
                  0
                ),
                _c(
                  "button",
                  {
                    attrs: { disabled: _vm.model.promotion.isFull },
                    on: { click: _vm.addPromotion },
                  },
                  [_c("fa-icon", { attrs: { icon: "plus" } })],
                  1
                ),
              ],
              1
            ),
            _vm.model.promotion.isSelected
              ? _c("div", { staticClass: "right" }, [
                  _c("div", { staticClass: "body" }, [
                    _c("div", { staticClass: "formGroupV2" }, [
                      _c("div", { staticClass: "header" }, [
                        _c("div", { staticClass: "row1" }, [
                          _c("span", { staticClass: "title" }, [
                            _vm._v("タイトル"),
                          ]),
                          _c(
                            "span",
                            {
                              staticClass: "help-button",
                              on: {
                                click: function ($event) {
                                  return _vm.model.helps.promotionTitle.switch()
                                },
                              },
                            },
                            [
                              _c("fa-icon", {
                                attrs: { icon: ["far", "question-circle"] },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _c("div", { staticClass: "row2" }, [
                          _c(
                            "div",
                            {
                              staticClass: "help-body",
                              class: {
                                hidden: _vm.model.helps.promotionTitle.hidden,
                              },
                            },
                            [_vm._m(15)]
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "body" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value:
                                _vm.model.promotion.selectedItem.title.value,
                              expression:
                                "model.promotion.selectedItem.title.value",
                            },
                          ],
                          staticClass: "width-300",
                          class: {
                            error:
                              _vm.model.promotion.selectedItem.title.isError,
                          },
                          attrs: { type: "text" },
                          domProps: {
                            value: _vm.model.promotion.selectedItem.title.value,
                          },
                          on: {
                            change: function ($event) {
                              _vm.validationCheck(
                                "promotion.items[" +
                                  _vm.model.promotion.items.indexOf(
                                    _vm.model.promotion.selectedItem
                                  ) +
                                  "].title"
                              )
                            },
                            input: [
                              function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.model.promotion.selectedItem.title,
                                  "value",
                                  $event.target.value
                                )
                              },
                              function ($event) {
                                _vm.validationCheck(
                                  "promotion.items[" +
                                    _vm.model.promotion.items.indexOf(
                                      _vm.model.promotion.selectedItem
                                    ) +
                                    "].title",
                                  true
                                )
                              },
                            ],
                          },
                        }),
                        _c(
                          "div",
                          {
                            staticClass: "errors",
                            class: {
                              hidden:
                                !_vm.model.promotion.selectedItem.title.isError,
                            },
                          },
                          _vm._l(
                            _vm.model.promotion.selectedItem.title.errors,
                            function (item2) {
                              return _c(
                                "div",
                                {
                                  key: _vm.model.promotion.selectedItem.title.errors.indexOf(
                                    item2
                                  ),
                                  staticClass: "error",
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "icon" },
                                    [
                                      _c("fa-icon", {
                                        attrs: { icon: "exclamation-circle" },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c("span", [_vm._v(_vm._s(item2.message))]),
                                ]
                              )
                            }
                          ),
                          0
                        ),
                      ]),
                    ]),
                    _c("hr"),
                    _c("div", { staticClass: "formGroupV2" }, [
                      _c("div", { staticClass: "header" }, [
                        _c("div", { staticClass: "row1" }, [
                          _c("span", { staticClass: "title" }, [
                            _vm._v("ボディー"),
                          ]),
                          _c(
                            "span",
                            {
                              staticClass: "help-button",
                              on: {
                                click: function ($event) {
                                  return _vm.model.helps.promotionBody.switch()
                                },
                              },
                            },
                            [
                              _c("fa-icon", {
                                attrs: { icon: ["far", "question-circle"] },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _c("div", { staticClass: "row2" }, [
                          _c(
                            "div",
                            {
                              staticClass: "help-body",
                              class: {
                                hidden: _vm.model.helps.promotionBody.hidden,
                              },
                            },
                            [_vm._m(16)]
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "body" }, [
                        _c("textarea", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value:
                                _vm.model.promotion.selectedItem.body.value,
                              expression:
                                "model.promotion.selectedItem.body.value",
                            },
                          ],
                          staticClass: "height-row-5",
                          class: {
                            error:
                              _vm.model.promotion.selectedItem.body.isError,
                          },
                          attrs: { cols: "60" },
                          domProps: {
                            value: _vm.model.promotion.selectedItem.body.value,
                          },
                          on: {
                            change: function ($event) {
                              _vm.validationCheck(
                                "promotion.items[" +
                                  _vm.model.promotion.items.indexOf(
                                    _vm.model.promotion.selectedItem
                                  ) +
                                  "].body"
                              )
                            },
                            input: [
                              function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.model.promotion.selectedItem.body,
                                  "value",
                                  $event.target.value
                                )
                              },
                              function ($event) {
                                _vm.validationCheck(
                                  "promotion.items[" +
                                    _vm.model.promotion.items.indexOf(
                                      _vm.model.promotion.selectedItem
                                    ) +
                                    "].body",
                                  true
                                )
                              },
                            ],
                          },
                        }),
                        _c(
                          "div",
                          {
                            staticClass: "errors",
                            class: {
                              hidden:
                                !_vm.model.promotion.selectedItem.body.isError,
                            },
                          },
                          _vm._l(
                            _vm.model.promotion.selectedItem.body.errors,
                            function (item2) {
                              return _c(
                                "div",
                                {
                                  key: _vm.model.promotion.selectedItem.body.errors.indexOf(
                                    item2
                                  ),
                                  staticClass: "error",
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "icon" },
                                    [
                                      _c("fa-icon", {
                                        attrs: { icon: "exclamation-circle" },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c("span", [_vm._v(_vm._s(item2.message))]),
                                ]
                              )
                            }
                          ),
                          0
                        ),
                      ]),
                    ]),
                    _c("hr"),
                    _c("div", { staticClass: "formGroupV2" }, [
                      _c("div", { staticClass: "header" }, [
                        _c("div", { staticClass: "row1" }, [
                          _c("span", { staticClass: "title" }, [
                            _vm._v("画像・動画"),
                          ]),
                          _c(
                            "span",
                            {
                              staticClass: "help-button",
                              on: {
                                click: function ($event) {
                                  return _vm.model.helps.promotionJumpTo.switch()
                                },
                              },
                            },
                            [
                              _c("fa-icon", {
                                attrs: { icon: ["far", "question-circle"] },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _c("div", { staticClass: "row2" }, [
                          _c(
                            "div",
                            {
                              staticClass: "help-body",
                              class: {
                                hidden: _vm.model.helps.promotionJumpTo.hidden,
                              },
                            },
                            [_vm._m(17)]
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "body" }, [
                        _c("div", { staticClass: "radio" }, [
                          _c("div", { staticClass: "radio-item pb15" }, [
                            _c("div", { staticClass: "main" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.model.promotion.selectedItem.type,
                                    expression:
                                      "model.promotion.selectedItem.type",
                                  },
                                ],
                                attrs: {
                                  id: "model.promotion.selectedItem.type.none",
                                  name: "model.promotion.selectedItem.type",
                                  type: "radio",
                                  value: "none",
                                },
                                domProps: {
                                  checked: _vm._q(
                                    _vm.model.promotion.selectedItem.type,
                                    "none"
                                  ),
                                },
                                on: {
                                  change: [
                                    function ($event) {
                                      return _vm.$set(
                                        _vm.model.promotion.selectedItem,
                                        "type",
                                        "none"
                                      )
                                    },
                                    function ($event) {
                                      _vm.validationCheck(
                                        "promotion.items[" +
                                          _vm.model.promotion.items.indexOf(
                                            _vm.model.promotion.selectedItem
                                          ) +
                                          "].type",
                                        true
                                      )
                                    },
                                  ],
                                },
                              }),
                              _c(
                                "label",
                                {
                                  attrs: {
                                    for: "model.promotion.selectedItem.type.none",
                                  },
                                },
                                [_vm._v("なし")]
                              ),
                            ]),
                          ]),
                          _c("div", { staticClass: "radio-item pb15" }, [
                            _c("div", { staticClass: "main" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.model.promotion.selectedItem.type,
                                    expression:
                                      "model.promotion.selectedItem.type",
                                  },
                                ],
                                attrs: {
                                  id: "model.promotion.selectedItem.type.image",
                                  name: "model.promotion.selectedItem.type",
                                  type: "radio",
                                  value: "image",
                                },
                                domProps: {
                                  checked: _vm._q(
                                    _vm.model.promotion.selectedItem.type,
                                    "image"
                                  ),
                                },
                                on: {
                                  change: [
                                    function ($event) {
                                      return _vm.$set(
                                        _vm.model.promotion.selectedItem,
                                        "type",
                                        "image"
                                      )
                                    },
                                    function ($event) {
                                      _vm.validationCheck(
                                        "promotion.items[" +
                                          _vm.model.promotion.items.indexOf(
                                            _vm.model.promotion.selectedItem
                                          ) +
                                          "].type",
                                        true
                                      )
                                    },
                                  ],
                                },
                              }),
                              _c(
                                "label",
                                {
                                  attrs: {
                                    for: "model.promotion.selectedItem.type.image",
                                  },
                                },
                                [_vm._v("画像")]
                              ),
                            ]),
                            _c("div", { staticClass: "body" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "formGroupV2",
                                  class: {
                                    disabled:
                                      _vm.model.promotion.selectedItem.type !=
                                      "image",
                                  },
                                },
                                [
                                  _c("div", { staticClass: "body" }, [
                                    _c("div", { staticClass: "fileupload" }, [
                                      _c("div", { staticClass: "left" }, [
                                        _c("div", { staticClass: "image" }, [
                                          _vm.model.promotion.selectedItem.image
                                            .base64Src != null
                                            ? _c("img", {
                                                attrs: {
                                                  src: _vm.model.promotion
                                                    .selectedItem.image
                                                    .base64Src,
                                                },
                                              })
                                            : _vm._e(),
                                          _vm.model.promotion.selectedItem.image
                                            .defaultFileImageUrl != null
                                            ? _c("img", {
                                                attrs: {
                                                  src: _vm.model.promotion
                                                    .selectedItem.image
                                                    .defaultFileImageUrl,
                                                },
                                              })
                                            : _vm._e(),
                                          !_vm.model.promotion.selectedItem
                                            .image.isSetSrc
                                            ? _c("span", [
                                                _vm._v(
                                                  "ファイルを選択してください"
                                                ),
                                              ])
                                            : _vm._e(),
                                        ]),
                                      ]),
                                      _c("div", { staticClass: "right" }, [
                                        _vm.model.promotion.selectedItem.image
                                          .loacalFilePath != null
                                          ? _c(
                                              "span",
                                              { staticClass: "path" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.model.promotion
                                                      .selectedItem.image
                                                      .loacalFilePath
                                                  )
                                                ),
                                                _c("fa-icon", {
                                                  attrs: { icon: "times" },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.deleteImage(
                                                        _vm.model.promotion
                                                          .selectedItem.image,
                                                        "model.promotion.selectedItem.image"
                                                      )
                                                    },
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _c(
                                          "button",
                                          {
                                            on: {
                                              click: function ($event) {
                                                return _vm.elementClick(
                                                  "model.promotion.selectedItem.image"
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "icon" },
                                              [
                                                _c("fa-icon", {
                                                  attrs: {
                                                    icon: "folder-open",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c("span", [
                                              _vm._v("ファイルを選択"),
                                            ]),
                                          ]
                                        ),
                                        _c("input", {
                                          attrs: {
                                            type: "file",
                                            id: "model.promotion.selectedItem.image",
                                            accept: "image/*",
                                          },
                                          on: {
                                            input: function ($event) {
                                              _vm.changeImage(
                                                $event,
                                                _vm.model.promotion.selectedItem
                                                  .image,
                                                "promotion.items[" +
                                                  _vm.model.promotion.items.indexOf(
                                                    _vm.model.promotion
                                                      .selectedItem
                                                  ) +
                                                  "].image"
                                              )
                                            },
                                          },
                                        }),
                                      ]),
                                    ]),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "errors",
                                        class: {
                                          hidden:
                                            !_vm.model.promotion.selectedItem
                                              .image.isError,
                                        },
                                      },
                                      _vm._l(
                                        _vm.model.promotion.selectedItem.image
                                          .errors,
                                        function (item2) {
                                          return _c(
                                            "div",
                                            {
                                              key: _vm.model.promotion.selectedItem.image.errors.indexOf(
                                                item2
                                              ),
                                              staticClass: "error",
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "icon" },
                                                [
                                                  _c("fa-icon", {
                                                    attrs: {
                                                      icon: "exclamation-circle",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c("span", [
                                                _vm._v(_vm._s(item2.message)),
                                              ]),
                                            ]
                                          )
                                        }
                                      ),
                                      0
                                    ),
                                  ]),
                                ]
                              ),
                            ]),
                          ]),
                          _c("div", { staticClass: "radio-item pb15" }, [
                            _c("div", { staticClass: "main" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.model.promotion.selectedItem.type,
                                    expression:
                                      "model.promotion.selectedItem.type",
                                  },
                                ],
                                attrs: {
                                  id: "model.promotion.selectedItem.type.youtube",
                                  name: "model.promotion.selectedItem.type",
                                  type: "radio",
                                  value: "youtube",
                                },
                                domProps: {
                                  checked: _vm._q(
                                    _vm.model.promotion.selectedItem.type,
                                    "youtube"
                                  ),
                                },
                                on: {
                                  change: [
                                    function ($event) {
                                      return _vm.$set(
                                        _vm.model.promotion.selectedItem,
                                        "type",
                                        "youtube"
                                      )
                                    },
                                    function ($event) {
                                      _vm.validationCheck(
                                        "promotion.items[" +
                                          _vm.model.promotion.items.indexOf(
                                            _vm.model.promotion.selectedItem
                                          ) +
                                          "].type",
                                        true
                                      )
                                    },
                                  ],
                                },
                              }),
                              _c(
                                "label",
                                {
                                  attrs: {
                                    for: "model.promotion.selectedItem.type.youtube",
                                  },
                                },
                                [_vm._v("動画（YouTube）")]
                              ),
                            ]),
                            _c("div", { staticClass: "body" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "formGroupV2",
                                  class: {
                                    disabled:
                                      _vm.model.promotion.selectedItem.type !=
                                      "youtube",
                                  },
                                },
                                [
                                  _c("div", { staticClass: "body" }, [
                                    _c("span", [
                                      _vm._v(
                                        "https://www.youtube.com/watch?v="
                                      ),
                                    ]),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value:
                                            _vm.model.promotion.selectedItem
                                              .youtube.value,
                                          expression:
                                            "\n                              model.promotion.selectedItem.youtube.value\n                            ",
                                        },
                                      ],
                                      class: {
                                        error:
                                          _vm.model.promotion.selectedItem
                                            .youtube.isError,
                                      },
                                      attrs: { type: "text" },
                                      domProps: {
                                        value:
                                          _vm.model.promotion.selectedItem
                                            .youtube.value,
                                      },
                                      on: {
                                        change: function ($event) {
                                          _vm.validationCheck(
                                            "promotion.items[" +
                                              _vm.model.promotion.items.indexOf(
                                                _vm.model.promotion.selectedItem
                                              ) +
                                              "].youtube"
                                          )
                                        },
                                        input: [
                                          function ($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.$set(
                                              _vm.model.promotion.selectedItem
                                                .youtube,
                                              "value",
                                              $event.target.value
                                            )
                                          },
                                          function ($event) {
                                            _vm.validationCheck(
                                              "promotion.items[" +
                                                _vm.model.promotion.items.indexOf(
                                                  _vm.model.promotion
                                                    .selectedItem
                                                ) +
                                                "].youtube",
                                              true
                                            )
                                          },
                                        ],
                                      },
                                    }),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "errors",
                                        class: {
                                          hidden:
                                            !_vm.model.promotion.selectedItem
                                              .youtube.isError,
                                        },
                                      },
                                      _vm._l(
                                        _vm.model.promotion.selectedItem.youtube
                                          .errors,
                                        function (item2) {
                                          return _c(
                                            "div",
                                            {
                                              key: _vm.model.promotion.selectedItem.youtube.errors.indexOf(
                                                item2
                                              ),
                                              staticClass: "error",
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "icon" },
                                                [
                                                  _c("fa-icon", {
                                                    attrs: {
                                                      icon: "exclamation-circle",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c("span", [
                                                _vm._v(_vm._s(item2.message)),
                                              ]),
                                            ]
                                          )
                                        }
                                      ),
                                      0
                                    ),
                                  ]),
                                ]
                              ),
                            ]),
                          ]),
                        ]),
                        _c(
                          "div",
                          {
                            staticClass: "errors",
                            class: {
                              hidden: !_vm.model.promotion.selectedItem.isError,
                            },
                          },
                          _vm._l(
                            _vm.model.promotion.selectedItem.errors,
                            function (item2) {
                              return _c(
                                "div",
                                {
                                  key: _vm.model.promotion.selectedItem.errors.indexOf(
                                    item2
                                  ),
                                  staticClass: "error",
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "icon" },
                                    [
                                      _c("fa-icon", {
                                        attrs: { icon: "exclamation-circle" },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c("span", [_vm._v(_vm._s(item2.message))]),
                                ]
                              )
                            }
                          ),
                          0
                        ),
                      ]),
                    ]),
                  ]),
                ])
              : _c("div", { staticClass: "right not" }, [
                  _c("span", [_vm._v("アイテムを選択してください")]),
                ]),
          ]),
          _c(
            "div",
            {
              staticClass: "errors",
              class: { hidden: !_vm.model.promotion.isError },
            },
            _vm._l(_vm.model.promotion.errors, function (item1) {
              return _c(
                "div",
                {
                  key: _vm.model.promotion.errors.indexOf(item1),
                  staticClass: "error",
                },
                [
                  _c(
                    "div",
                    { staticClass: "icon" },
                    [_c("fa-icon", { attrs: { icon: "exclamation-circle" } })],
                    1
                  ),
                  _c("span", [_vm._v(_vm._s(item1.message))]),
                ]
              )
            }),
            0
          ),
        ]),
      ]),
      _c("div", { staticClass: "bottom" }, [
        _vm.model.saveButtonCompleted
          ? _c("div", { staticClass: "saveButtonCompleted" }, [
              _c(
                "div",
                { staticClass: "icon" },
                [_c("fa-icon", { attrs: { icon: "check" } })],
                1
              ),
              _c("span", [_vm._v("サイト設定を変更しました")]),
            ])
          : _vm._e(),
        !_vm.validationCheck("all", true, true)
          ? _c("div", { staticClass: "saveButtonValidationError" }, [
              _c(
                "div",
                { staticClass: "icon" },
                [_c("fa-icon", { attrs: { icon: "exclamation-circle" } })],
                1
              ),
              _c("span", [_vm._v("不備のある項目が存在します")]),
            ])
          : _vm._e(),
        _c(
          "button",
          {
            attrs: {
              disabled:
                !_vm.model.saveButtonActive ||
                !_vm.validationCheck("all", true, true),
            },
            on: { click: _vm.save },
          },
          [
            _vm.model.saveButtonLoading
              ? _c("div", { staticClass: "spinner bubble" })
              : _vm._e(),
            !_vm.model.saveButtonLoading
              ? _c("fa-icon", { attrs: { icon: "save" } })
              : _vm._e(),
            !_vm.model.saveButtonLoading
              ? _c("span", [_vm._v("サイト設定を適用")])
              : _vm._e(),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(" ヘルプテキスト"),
      _c("br"),
      _vm._v(" ヘルプテキスト"),
      _c("br"),
      _vm._v(" ヘルプテキスト"),
      _c("br"),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(" ヘルプテキスト"),
      _c("br"),
      _vm._v(" ヘルプテキスト"),
      _c("br"),
      _vm._v(" ヘルプテキスト"),
      _c("br"),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(" ヘルプテキスト"),
      _c("br"),
      _vm._v(" ヘルプテキスト"),
      _c("br"),
      _vm._v(" ヘルプテキスト"),
      _c("br"),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(" ヘルプテキスト"),
      _c("br"),
      _vm._v(" ヘルプテキスト"),
      _c("br"),
      _vm._v(" ヘルプテキスト"),
      _c("br"),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(" ヘルプテキスト"),
      _c("br"),
      _vm._v(" ヘルプテキスト"),
      _c("br"),
      _vm._v(" ヘルプテキスト"),
      _c("br"),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(" ヘルプテキスト"),
      _c("br"),
      _vm._v(" ヘルプテキスト"),
      _c("br"),
      _vm._v(" ヘルプテキスト"),
      _c("br"),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(" ヘルプテキスト"),
      _c("br"),
      _vm._v(" ヘルプテキスト"),
      _c("br"),
      _vm._v(" ヘルプテキスト"),
      _c("br"),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(" ヘルプテキスト"),
      _c("br"),
      _vm._v(" ヘルプテキスト"),
      _c("br"),
      _vm._v(" ヘルプテキスト"),
      _c("br"),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(" ヘルプテキスト"),
      _c("br"),
      _vm._v(" ヘルプテキスト"),
      _c("br"),
      _vm._v(" ヘルプテキスト"),
      _c("br"),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(" ヘルプテキスト"),
      _c("br"),
      _vm._v(" ヘルプテキスト"),
      _c("br"),
      _vm._v(" ヘルプテキスト"),
      _c("br"),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(" ヘルプテキスト"),
      _c("br"),
      _vm._v(" ヘルプテキスト"),
      _c("br"),
      _vm._v(" ヘルプテキスト"),
      _c("br"),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(" ヘルプテキスト"),
      _c("br"),
      _vm._v(" ヘルプテキスト"),
      _c("br"),
      _vm._v(" ヘルプテキスト"),
      _c("br"),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(" ヘルプテキスト"),
      _c("br"),
      _vm._v(" ヘルプテキスト"),
      _c("br"),
      _vm._v(" ヘルプテキスト"),
      _c("br"),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(" ヘルプテキスト"),
      _c("br"),
      _vm._v(" ヘルプテキスト"),
      _c("br"),
      _vm._v(" ヘルプテキスト"),
      _c("br"),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(" ヘルプテキスト"),
      _c("br"),
      _vm._v(" ヘルプテキスト"),
      _c("br"),
      _vm._v(" ヘルプテキスト"),
      _c("br"),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(" ヘルプテキスト"),
      _c("br"),
      _vm._v(" ヘルプテキスト"),
      _c("br"),
      _vm._v(" ヘルプテキスト"),
      _c("br"),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(" ヘルプテキスト"),
      _c("br"),
      _vm._v(" ヘルプテキスト"),
      _c("br"),
      _vm._v(" ヘルプテキスト"),
      _c("br"),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(" ヘルプテキスト"),
      _c("br"),
      _vm._v(" ヘルプテキスト"),
      _c("br"),
      _vm._v(" ヘルプテキスト"),
      _c("br"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }