// eslint-disable-next-line
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import BaseVue from "@/plugin/BaseVue";
import * as model from "./Model";
import axios from "axios";
import TextareaAutoResize from "@/components/TextareaAutoResize/TextareaAutoResize.vue";
import { commonModule } from "@/store/modules/Common";
import AWS from "aws-sdk";

@Component({
  components: {
    TextareaAutoResize,
  },
})
export default class extends BaseVue {
  public model: model.Model = new model.Model();
  public created() {
    const routeInstance = this.$route;
    this.setMeta(routeInstance);
  }
  public async mounted() {
    await this.authCheck();
    this.model.pageLoading = true;
    this.loadingOn();
    await this.mainLoading();
    this.loadingOff();
    this.model.pageLoading = false;
  }

  public async mainLoading() {
    let response: any;
    await axios
      .post(
        `https://${process.env.VUE_APP_API_URL}/ChannelHomeEdit/Main`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization:
              "Bearer " + (await this.creatorUserManager.getJwtToken()),
          },
        }
      )
      .then((response) => {
        response = response.data;
        this.model.homeImagePC.loacalFilePath = null;
        this.model.homeImagePC.base64Src = null;
        this.model.homeImagePC.defaultFileImageUrl = response.homeImageUrlPC;
        this.model.homeImageSP.loacalFilePath = null;
        this.model.homeImageSP.base64Src = null;
        this.model.homeImageSP.defaultFileImageUrl = response.homeImageUrlSP;
        this.model.catchCopyTitle.value = response.catchCopyTitle;
        this.model.catchCopyBody.value = response.catchCopyBody;
        this.model.signUpButton.value = response.signUpButton;
        this.model.catchPositionPC.value = response.catchPositionPC;
        this.model.catchPositionSP.value = response.catchPositionSP;
        this.model.homeImageTextColorPC.value = response.homeImageTextColorPC;
        this.model.homeImageTextColorSP.value = response.homeImageTextColorSP;
        this.model.promotion.selectedItem = null;
        this.model.promotion.items = new Array<model.InputPromotion>();
        for (const item of response.promotions) {
          const promotion = new model.InputPromotion(this.model.promotion);
          promotion.id = item.id;
          promotion.title.value = item.title;
          promotion.body.value = item.body;
          promotion.image.loacalFilePath = null;
          promotion.image.base64Src = null;
          switch (item.type) {
            case "image":
              promotion.type = model.InputPromotionType.image;
              promotion.image.defaultFileImageUrl = item.imageUrl;
              break;
            case "youtube":
              promotion.type = model.InputPromotionType.youtube;
              promotion.youtube.value = item.youTubeWatchId;
              break;
            case "none":
              promotion.type = model.InputPromotionType.none;
              break;
          }
          this.model.promotion.items.push(promotion);
        }
        this.model.point.selectedItem = null;
        this.model.point.items = new Array<model.InputPoint>();
        for (const item of response.points) {
          const point = new model.InputPoint(this.model.point);
          point.id = item.id;
          point.title.value = item.title;
          point.body.value = item.body;
          point.icon.value = item.icon;
          point.color.value = item.color;
          this.model.point.items.push(point);
        }
      })
      .catch((ex) => {
        throw ex;
      });
  }
  public selectPromotion(item: model.InputPromotion | null): void {
    const element: any = document.getElementById(
      "model.promotion.selectedItem.image"
    );
    if (element != null) {
      element.value = "";
    }
    this.model.promotion.selectedItem = item;
    this.validationCheck("promotion");
  }
  public async addPromotion() {
    this.model.promotion.items.push(
      new model.InputPromotion(this.model.promotion)
    );
  }
  public async deletePromotion(item: model.InputPromotion) {
    if (!window.confirm("削除しますか？")) {
      return;
    }
    let reSelect = false;
    if (item == this.model.promotion.selectedItem) {
      reSelect = true;
    }
    this.model.promotion.items.splice(
      this.model.promotion.items.indexOf(item),
      1
    );
    if (reSelect) {
      this.selectPromotion(null);
    } else {
      this.validationCheck("promotion");
    }
  }
  public selectPoint(item: model.InputPoint | null): void {
    this.model.point.selectedItem = item;
    this.validationCheck("point");
  }
  public async addPoint() {
    this.model.point.items.push(new model.InputPoint(this.model.point));
  }
  public async deletePoint(item: model.InputPoint) {
    if (!window.confirm("削除しますか？")) {
      return;
    }
    let reSelect = false;
    if (item == this.model.point.selectedItem) {
      reSelect = true;
    }
    this.model.point.items.splice(this.model.point.items.indexOf(item), 1);
    if (reSelect) {
      this.selectPoint(null);
    } else {
      this.validationCheck("point");
    }
  }
  public validationCheck(target = "", inputing = false, auto = false): boolean {
    if (!auto && !inputing) {
      this.model.saveButtonCompleted = false;
    }
    let result = true;
    let input: any;
    let errors: any;
    if (target == "all" || target == "catchCopyTitle") {
      input = this.model.catchCopyTitle;
      errors = new Array<model.Error>();
      //
      if (!inputing) {
        input.value = input.value.trim();
      }
      if (input.value.length > input.maxLength) {
        errors.push(
          new model.Error(`${input.maxLength}文字以下で入力してください`)
        );
      }
      //
      if (inputing) {
        if (input.errors.length > errors.length) {
          input.errors = errors;
        }
      } else {
        input.errors = errors;
      }
      if (input.isError) {
        result = false;
      }
      //
    }
    if (target == "all" || target == "catchCopyBody") {
      input = this.model.catchCopyBody;
      errors = new Array<model.Error>();
      //
      if (!inputing) {
        input.value = input.value.trim();
      }
      if (input.value.length > input.maxLength) {
        errors.push(
          new model.Error(`${input.maxLength}文字以下で入力してください`)
        );
      }
      //
      if (inputing) {
        if (input.errors.length > errors.length) {
          input.errors = errors;
        }
      } else {
        input.errors = errors;
      }
      if (input.isError) {
        result = false;
      }
      //
    }
    if (target == "all" || target == "signUpButton") {
      input = this.model.signUpButton;
      errors = new Array<model.Error>();
      //
      if (!inputing) {
        input.value = input.value.trim();
      }
      if (input.value.length > input.maxLength) {
        errors.push(
          new model.Error(`${input.maxLength}文字以下で入力してください`)
        );
      }
      //
      if (inputing) {
        if (input.errors.length > errors.length) {
          input.errors = errors;
        }
      } else {
        input.errors = errors;
      }
      if (input.isError) {
        result = false;
      }
      //
    }
    if (
      target == "all" ||
      target.match(/^promotion\.items\[([0-9]+)\]\.title/g) != null
    ) {
      let index = 0;
      if (target != "all") {
        const match = target.match(/^promotion\.items\[([0-9]+)\]\.title/);
        if (match == null) {
          return false;
        }
        index = Number(match[1]);
      }
      for (let i = 0; i <= this.model.promotion.items.length - 1; i++) {
        if (target != "all" && i != index) {
          continue;
        }
        input = this.model.promotion.items[i].title;
        errors = new Array<model.Error>();
        //
        if (!inputing) {
          input.value = input.value.trim();
        }
        if (input.value.length === 0) {
          errors.push(new model.Error("必須項目です"));
        }
        if (input.value.length > input.maxLength) {
          errors.push(
            new model.Error(`${input.maxLength}文字以下で入力してください`)
          );
        }
        //
        if (inputing) {
          if (input.errors.length > errors.length) {
            input.errors = errors;
          }
        } else {
          input.errors = errors;
        }
        if (input.isError) {
          result = false;
        }
      }
      //
    }
    if (
      target == "all" ||
      target.match(/^promotion\.items\[([0-9]+)\]\.body/g) != null
    ) {
      let index = 0;
      if (target != "all") {
        const match = target.match(/^promotion\.items\[([0-9]+)\]\.body/);
        if (match == null) {
          return false;
        }
        index = Number(match[1]);
      }
      for (let i = 0; i <= this.model.promotion.items.length - 1; i++) {
        if (target != "all" && i != index) {
          continue;
        }
        input = this.model.promotion.items[i].body;
        errors = new Array<model.Error>();
        //
        if (!inputing) {
          input.value = input.value.trim();
        }
        if (input.value.length > input.maxLength) {
          errors.push(
            new model.Error(`${input.maxLength}文字以下で入力してください`)
          );
        }
        //
        if (inputing) {
          if (input.errors.length > errors.length) {
            input.errors = errors;
          }
        } else {
          input.errors = errors;
        }
        if (input.isError) {
          result = false;
        }
      }
      //
    }
    if (
      target == "all" ||
      target.match(/^promotion\.items\[([0-9]+)\]\.image/g) != null ||
      target.match(/^promotion\.items\[([0-9]+)\]\.type/g) != null
    ) {
      let index = 0;
      if (target != "all") {
        const match = target.match(/^promotion\.items\[([0-9]+)\]/);
        if (match == null) {
          return false;
        }
        index = Number(match[1]);
      }
      for (let i = 0; i <= this.model.promotion.items.length - 1; i++) {
        if (target != "all" && i != index) {
          continue;
        }
        input = this.model.promotion.items[i].image;
        errors = new Array<model.Error>();
        //
        if (
          this.model.promotion.items[i].type == model.InputPromotionType.image
        ) {
          if (!input.isSetSrc) {
            errors.push(new model.Error("必須項目です"));
          }
        }
        //
        if (inputing) {
          if (input.errors.length > errors.length) {
            input.errors = errors;
          }
        } else {
          input.errors = errors;
        }
        if (input.isError) {
          result = false;
        }
      }
      //
    }
    if (
      target == "all" ||
      target.match(/^promotion\.items\[([0-9]+)\]\.youtube/g) != null ||
      target.match(/^promotion\.items\[([0-9]+)\]\.type/g) != null
    ) {
      let index = 0;
      if (target != "all") {
        const match = target.match(/^promotion\.items\[([0-9]+)\]/);
        if (match == null) {
          return false;
        }
        index = Number(match[1]);
      }
      for (let i = 0; i <= this.model.promotion.items.length - 1; i++) {
        if (target != "all" && i != index) {
          continue;
        }
        input = this.model.promotion.items[i].youtube;
        errors = new Array<model.Error>();
        //
        if (
          this.model.promotion.items[i].type == model.InputPromotionType.youtube
        ) {
          if (!inputing) {
            input.value = input.value.trim();
            input.value = this.hankaku2Zenkaku(input.value);
            input.value = this.youtubeWatch(input.value);
          }
          if (input.value.length === 0) {
            errors.push(new model.Error("必須項目です"));
          } else if (input.value.length > input.maxLength) {
            errors.push(
              new model.Error(`${input.maxLength}文字以下で入力してください`)
            );
          }
        }
        //
        if (inputing) {
          if (input.errors.length > errors.length) {
            input.errors = errors;
          }
        } else {
          input.errors = errors;
        }
        if (input.isError) {
          result = false;
        }
      }
      //
    }
    if (
      target == "all" ||
      target.match(/^promotion\.items\[([0-9]+)\]/g) != null
    ) {
      let index = 0;
      if (target != "all") {
        const match = target.match(/^promotion\.items\[([0-9]+)\]/);
        if (match == null) {
          return false;
        }
        index = Number(match[1]);
      }
      for (let i = 0; i <= this.model.promotion.items.length - 1; i++) {
        if (target != "all" && i != index) {
          continue;
        }
        input = this.model.promotion.items[i];
        errors = new Array<model.Error>();
        const isError = false;
        //
        if (input.title.isError && errors.length == 0) {
          errors.push(new model.Error("不備のある項目が存在します"));
        }
        if (input.body.isError && errors.length == 0) {
          errors.push(new model.Error("不備のある項目が存在します"));
        }
        switch (input.type) {
          case model.InputPromotionType.image:
            if (input.image.isError && errors.length == 0) {
              errors.push(new model.Error("不備のある項目が存在します"));
            }
            break;
          case model.InputPromotionType.youtube:
            if (input.youtube.isError && errors.length == 0) {
              errors.push(new model.Error("不備のある項目が存在します"));
            }
            break;
        }
        //
        if (inputing) {
          if (input.errors.length > errors.length) {
            input.errors = errors;
          }
        } else {
          input.errors = errors;
        }
        if (input.isError) {
          result = false;
        }
      }
      //
    }
    if (target == "all" || target.match(/^promotion/g) != null) {
      input = this.model.promotion;
      errors = new Array<model.Error>();
      //
      for (let i = 0; i <= this.model.promotion.items.length - 1; i++) {
        if (this.model.promotion.items[i].isError && errors.length == 0) {
          errors.push(new model.Error("不備のある項目が存在します"));
        }
      }
      //
      if (inputing) {
        if (input.errors.length > errors.length) {
          input.errors = errors;
        }
      } else {
        input.errors = errors;
      }
      if (input.isError) {
        result = false;
      }
      //
    }
    if (
      target == "all" ||
      target.match(/^point\.items\[([0-9]+)\]\.title/g) != null
    ) {
      let index = 0;
      if (target != "all") {
        const match = target.match(/^point\.items\[([0-9]+)\]\.title/);
        if (match == null) {
          return false;
        }
        index = Number(match[1]);
      }
      for (let i = 0; i <= this.model.point.items.length - 1; i++) {
        if (target != "all" && i != index) {
          continue;
        }
        input = this.model.point.items[i].title;
        errors = new Array<model.Error>();
        //
        if (!inputing) {
          input.value = input.value.trim();
        }
        if (input.value.length === 0) {
          errors.push(new model.Error("必須項目です"));
        }
        if (input.value.length > input.maxLength) {
          errors.push(
            new model.Error(`${input.maxLength}文字以下で入力してください`)
          );
        }
        //
        if (inputing) {
          if (input.errors.length > errors.length) {
            input.errors = errors;
          }
        } else {
          input.errors = errors;
        }
        if (input.isError) {
          result = false;
        }
      }
      //
    }
    if (
      target == "all" ||
      target.match(/^point\.items\[([0-9]+)\]\.body/g) != null
    ) {
      let index = 0;
      if (target != "all") {
        const match = target.match(/^point\.items\[([0-9]+)\]\.body/);
        if (match == null) {
          return false;
        }
        index = Number(match[1]);
      }
      for (let i = 0; i <= this.model.point.items.length - 1; i++) {
        if (target != "all" && i != index) {
          continue;
        }
        input = this.model.point.items[i].body;
        errors = new Array<model.Error>();
        //
        if (!inputing) {
          input.value = input.value.trim();
        }
        if (input.value.length > input.maxLength) {
          errors.push(
            new model.Error(`${input.maxLength}文字以下で入力してください`)
          );
        }
        //
        if (inputing) {
          if (input.errors.length > errors.length) {
            input.errors = errors;
          }
        } else {
          input.errors = errors;
        }
        if (input.isError) {
          result = false;
        }
      }
      //
    }
    if (
      target == "all" ||
      target.match(/^point\.items\[([0-9]+)\]\.icon/g) != null
    ) {
      let index = 0;
      if (target != "all") {
        const match = target.match(/^point\.items\[([0-9]+)\]\.icon/);
        if (match == null) {
          return false;
        }
        index = Number(match[1]);
      }
      for (let i = 0; i <= this.model.point.items.length - 1; i++) {
        if (target != "all" && i != index) {
          continue;
        }
        input = this.model.point.items[i].icon;
        errors = new Array<model.Error>();
        //
        if (!inputing) {
          input.value = input.value.trim();
        }
        if (input.value.length === 0) {
          errors.push(new model.Error("必須項目です"));
        }
        if (input.value.length > input.maxLength) {
          errors.push(
            new model.Error(`${input.maxLength}文字以下で入力してください`)
          );
        }
        //
        if (inputing) {
          if (input.errors.length > errors.length) {
            input.errors = errors;
          }
        } else {
          input.errors = errors;
        }
        if (input.isError) {
          result = false;
        }
      }
      //
    }
    if (
      target == "all" ||
      target.match(/^point\.items\[([0-9]+)\]\.color/g) != null
    ) {
      let index = 0;
      if (target != "all") {
        const match = target.match(/^point\.items\[([0-9]+)\]\.color/);
        if (match == null) {
          return false;
        }
        index = Number(match[1]);
      }
      for (let i = 0; i <= this.model.point.items.length - 1; i++) {
        if (target != "all" && i != index) {
          continue;
        }
        input = this.model.point.items[i].color;
        errors = new Array<model.Error>();
        //
        if (!inputing) {
          input.value = input.value.trim();
        }
        if (input.value.length === 0) {
          errors.push(new model.Error("必須項目です"));
        }
        if (input.value.length > input.maxLength) {
          errors.push(
            new model.Error(`${input.maxLength}文字以下で入力してください`)
          );
        }
        //
        if (inputing) {
          if (input.errors.length > errors.length) {
            input.errors = errors;
          }
        } else {
          input.errors = errors;
        }
        if (input.isError) {
          result = false;
        }
      }
      //
    }
    if (target == "all" || target.match(/^point\.items\[([0-9]+)\]/g) != null) {
      let index = 0;
      if (target != "all") {
        const match = target.match(/^point\.items\[([0-9]+)\]/);
        if (match == null) {
          return false;
        }
        index = Number(match[1]);
      }
      for (let i = 0; i <= this.model.point.items.length - 1; i++) {
        if (target != "all" && i != index) {
          continue;
        }
        input = this.model.point.items[i];
        errors = new Array<model.Error>();
        const isError = false;
        //
        if (input.title.isError && errors.length == 0) {
          errors.push(new model.Error("不備のある項目が存在します"));
        }
        if (input.body.isError && errors.length == 0) {
          errors.push(new model.Error("不備のある項目が存在します"));
        }
        if (input.icon.isError && errors.length == 0) {
          errors.push(new model.Error("不備のある項目が存在します"));
        }
        if (input.color.isError && errors.length == 0) {
          errors.push(new model.Error("不備のある項目が存在します"));
        }
        //
        if (inputing) {
          if (input.errors.length > errors.length) {
            input.errors = errors;
          }
        } else {
          input.errors = errors;
        }
        if (input.isError) {
          result = false;
        }
      }
      //
    }
    if (target == "all" || target.match(/^point/g) != null) {
      input = this.model.point;
      errors = new Array<model.Error>();
      //
      for (let i = 0; i <= this.model.point.items.length - 1; i++) {
        if (this.model.point.items[i].isError && errors.length == 0) {
          errors.push(new model.Error("不備のある項目が存在します"));
        }
      }
      //
      if (inputing) {
        if (input.errors.length > errors.length) {
          input.errors = errors;
        }
      } else {
        input.errors = errors;
      }
      if (input.isError) {
        result = false;
      }
      //
    }

    return result;
  }
  public async save() {
    this.model.pageLoading = true;
    this.model.saveButtonActive = false;
    this.model.saveButtonLoading = true;
    const validationCheck = this.validationCheck("all");
    if (!validationCheck) {
      await this.sleep(1000);
      this.model.saveButtonLoading = false;
      this.model.saveButtonActive = true;
      this.model.pageLoading = false;
      return;
    }

    let response: any;
    this.loadingOn();
    const promotionImages = [];
    for (const item of this.model.promotion.items) {
      let promotionImage = null;
      if (
        item.image.base64Src != null &&
        item.type == model.InputPromotionType.image
      ) {
        const promotionImageParts = [];
        try {
          response = await axios.post(
            `https://${process.env.VUE_APP_API_URL}/ChannelHomeEdit/PromotionUpload`,
            {
              extension: item.image.loacalFileExtension,
            },
            {
              headers: {
                "Content-Type": "application/json",
                Authorization:
                  "Bearer " + (await this.creatorUserManager.getJwtToken()),
              },
            }
          );
          response = response.data;
        } catch (ex) {
          throw ex;
        }

        await this.creatorUserManager.credential();

        const s3 = new AWS.S3({
          params: {
            Region: response.region,
            Bucket: response.bucket,
          },
        });
        const uploadPartResult = await s3
          .uploadPart({
            Bucket: response.bucket,
            Key: response.key,
            UploadId: response.uploadId,
            PartNumber: 1,
            Body: this.base64toUint8Array(item.image.base64Src.split(",")[1]),
          })
          .promise();
        promotionImageParts.push({
          partNumber: 1,
          eTag: uploadPartResult.ETag,
        });
        promotionImage = {
          uploadId: response.uploadId,
          parts: promotionImageParts,
          key: response.key,
        };
      }
      promotionImages.push(promotionImage);
    }
    let homeImagePC = null;
    {
      if (this.model.homeImagePC.base64Src != null) {
        const homeImageParts = [];
        try {
          response = await axios.post(
            `https://${process.env.VUE_APP_API_URL}/ChannelHomeEdit/ImageUploadPC`,
            {
              extension: this.model.homeImagePC.loacalFileExtension,
            },
            {
              headers: {
                "Content-Type": "application/json",
                Authorization:
                  "Bearer " + (await this.creatorUserManager.getJwtToken()),
              },
            }
          );
          response = response.data;
        } catch (ex) {
          throw ex;
        }

        await this.creatorUserManager.credential();

        const s3 = new AWS.S3({
          params: {
            Region: response.region,
            Bucket: response.bucket,
          },
        });
        const uploadPartResult = await s3
          .uploadPart({
            Bucket: response.bucket,
            Key: response.key,
            UploadId: response.uploadId,
            PartNumber: 1,
            Body: this.base64toUint8Array(
              this.model.homeImagePC.base64Src.split(",")[1]
            ),
          })
          .promise();
        homeImageParts.push({
          partNumber: 1,
          eTag: uploadPartResult.ETag,
        });
        homeImagePC = {
          uploadId: response.uploadId,
          parts: homeImageParts,
          key: response.key,
        };
      }
    }
    let homeImageSP = null;
    {
      if (this.model.homeImageSP.base64Src != null) {
        const homeImageParts = [];
        try {
          response = await axios.post(
            `https://${process.env.VUE_APP_API_URL}/ChannelHomeEdit/ImageUploadSP`,
            {
              extension: this.model.homeImageSP.loacalFileExtension,
            },
            {
              headers: {
                "Content-Type": "application/json",
                Authorization:
                  "Bearer " + (await this.creatorUserManager.getJwtToken()),
              },
            }
          );
          response = response.data;
        } catch (ex) {
          throw ex;
        }

        await this.creatorUserManager.credential();

        const s3 = new AWS.S3({
          params: {
            Region: response.region,
            Bucket: response.bucket,
          },
        });
        const uploadPartResult = await s3
          .uploadPart({
            Bucket: response.bucket,
            Key: response.key,
            UploadId: response.uploadId,
            PartNumber: 1,
            Body: this.base64toUint8Array(
              this.model.homeImageSP.base64Src.split(",")[1]
            ),
          })
          .promise();
        homeImageParts.push({
          partNumber: 1,
          eTag: uploadPartResult.ETag,
        });
        homeImageSP = {
          uploadId: response.uploadId,
          parts: homeImageParts,
          key: response.key,
        };
      }
    }

    const promotions = [];
    for (const item of this.model.promotion.items) {
      switch (item.type.toString()) {
        case "image":
          promotions.push({
            id: item.id,
            title: item.title.value,
            body: item.body.value,
            type: item.type.toString(),
            image: promotionImages[this.model.promotion.items.indexOf(item)],
            youTubeWatchId: "",
          });
          break;
        case "youtube":
          promotions.push({
            id: item.id,
            title: item.title.value,
            body: item.body.value,
            type: item.type.toString(),
            image: null,
            youTubeWatchId: item.youtube.value,
          });
          break;
        case "none":
          promotions.push({
            id: item.id,
            title: item.title.value,
            body: item.body.value,
            type: item.type.toString(),
            image: null,
            youTubeWatchId: "",
          });
          break;
      }
    }

    const points = [];
    for (const item of this.model.point.items) {
      points.push({
        id: item.id,
        title: item.title.value,
        body: item.body.value,
        icon: item.icon.value,
        color: item.color.value,
      });
    }

    await axios
      .post(
        `https://${process.env.VUE_APP_API_URL}/ChannelHomeEdit/Save`,
        {
          catchCopyTitle: this.model.catchCopyTitle.value,
          catchCopyBody: this.model.catchCopyBody.value,
          signUpButton: this.model.signUpButton.value,
          catchPositionPC: this.model.catchPositionPC.value,
          catchPositionSP: this.model.catchPositionSP.value,
          homeImageTextColorPC: this.model.homeImageTextColorPC.value,
          homeImageTextColorSP: this.model.homeImageTextColorSP.value,
          homeImagePC: homeImagePC,
          homeImageSP: homeImageSP,
          promotions: promotions,
          points: points,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization:
              "Bearer " + (await this.creatorUserManager.getJwtToken()),
          },
        }
      )
      .then(async (response) => {
        response = response.data;
        this.model.saveButtonCompleted = true;
        commonModule.setChannelName(this.model.name.value);
        await this.mainLoading();
      })
      .catch((ex) => {
        throw ex;
      });
    this.loadingOff();
    this.model.saveButtonActive = true;
    this.model.saveButtonLoading = false;
    this.model.pageLoading = false;
  }
  public async deleteImage(target: model.InputImage, id: string) {
    if (!window.confirm("削除しますか？")) {
      return;
    }
    target.loacalFilePath = null;
    target.base64Src = null;
    target.defaultFileImageUrl = null;
    const element: any = document.getElementById(id);
    if (element != null) {
      element.value = "";
    }
  }
  public async elementClick(id: string) {
    const element = document.getElementById(id);
    if (element != null) {
      element.click();
    }
  }
  public changeImageTarget: model.InputImage | null = null;
  public changeImageValidationTarget: string | null = null;
  public async changeImage(
    e: any,
    target: model.InputImage,
    validationTarget: string
  ) {
    const file = e.target.files[0];
    if (file == null) {
      return;
    }
    this.changeImageTarget = target;
    this.changeImageValidationTarget = validationTarget;

    this.model.saveButtonActive = false;
    this.changeImageTarget.loacalFilePath = null;
    this.changeImageTarget.defaultFileImageUrl = null;
    this.changeImageTarget.base64Src = null;
    this.changeImageTarget.loacalFilePath = file.name;
    const reader = new FileReader();
    reader.onload = this.changeImageLoaded;
    reader.readAsDataURL(file);
  }
  public changeImageLoaded(event: any) {
    if (this.changeImageTarget == null) {
      return;
    }
    if (this.changeImageValidationTarget == null) {
      return;
    }
    this.changeImageTarget.base64Src = event.target.result;
    this.validationCheck(this.changeImageValidationTarget);
    this.model.saveButtonActive = true;
  }
}
